import React , {useState} from 'react'
import singapore from '../components/maps/singapore.json'
import indiaMap from '../components/maps/indiaMap.json';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useMediaQuery } from "react-responsive";


function SingaporeMap() {

 const PROJECTION_CONFIG = {
    scale:  68225,
    center:[103.6794394,1.3139946],
  };
  const [tooltipContent, setTooltipContent] = useState("");
  const [stateSelectedId, setStateSelectedId] = useState(null);


  return (
    <div>
    
      <ComposableMap
          projectionConfig={PROJECTION_CONFIG}
          projection="geoMercator"
          width={1080}
          height={600}
          data-tip=""
        >
          <Geographies geography={singapore}>
            {({ geographies }) =>
             
            geographies.map((geo, index) => (
                <a id="tooltip-anchor" key={index}>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      setTooltipContent(`${geo.properties.name}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    fill="#EAEAEC"
                    stroke="#999"
                    strokeWidth="0.50"
                    style={{
                      default: {
                        fill: "#f1edf5",
                        outline: "none",
                      },
                      hover: {
                        fill: "#6f2da8",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#6f2da8",
                        outline: "none",
                      },
                    }}
                    onClick={() => {
                      setStateSelectedId(geo.id);
                    }}
                  />
                </a>
              ))
            }
          </Geographies>
        </ComposableMap>

    </div>
  )
}

export default SingaporeMap