import React from "react";
import { useMediaQuery } from "react-responsive";


function Features() {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 610px)" });

  return (
    <div className={ isSmallScreen ? "flex column gap-1 ":"flex gap-2 mt-2 "}>
      <div className="flex align-center justify-center column box p-2 text-algin-center ">
        <img
          src="../images/bin.png"
          style={{ width: "100px", height: "100px" }}
        />
        <b className="normal-size mt-1 ">Track Every Bin in Real Time</b>
        <p className="grey-text  small-text  ">
          {" "}
          Stay updated on the status of all your metaBins at any given moment.
          Our dashboard provides real-time tracking, allowing you to monitor bin
          fill levels, location, and overall performance.{" "}
        </p>
      </div>

      <div className="flex align-center justify-center column box p-2 text-algin-center">
        <img
          src="../images/analytics.png"
          style={{ width: "100px", height: "100px" }}
        />
        <b className="normal-size mt-1">Intuitive Admin Dashboard</b>
        <p className="grey-text  small-text">
          {" "}
          Manage your entire metaBin ecosystem with ease using our user-friendly
          admin dashboard. Monitor bin statuses, review transaction logs, manage
          user accounts, and access advanced settings all in one centralized
          location
        </p>
      </div>

      <div className="flex align-center justify-center column box p-2 text-algin-center ">
        <img
          src="../images/collaboration.png"
          style={{ width: "100px", height: "100px" }}
        />
        <b className="normal-size mt-1">
          Integration with Recycling Partners
        </b>
        <p className="grey-text  small-text ">
          Connect your metaBin dashboard with trusted recycling partners for a
          seamless pickup process. Our platform integrates with recycling
          partner systems, allowing for efficient communication and
          coordination.
        </p>
      </div>
    </div>
  );
}

export default Features;
