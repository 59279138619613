import React, { useEffect } from "react";
import { Tab, Tabs } from "react-materialize";
import ActiveBins from "./ActiveBins";
import UnActiveBins from "./UnActiveBins";
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaBinsAction } from "../../redux/action/MetaBinAction";
import { Card } from "react-materialize";

function MetaBinsStatus() {
  const dispatch = useDispatch();

  const { metaBinsList, loading } = useSelector((state) => state.allMetaBins);

  useEffect(() => {
    dispatch(getAllMetaBinsAction());
  }, [dispatch]);
  return (
    <div className=" p-1 metabin-card " style={{ height: "100%" }}>
      <b>MetaBin Status</b>
      <Tabs className="mt-1">
        <Tab
          active
          title="Active"
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
        >
          <ActiveBins metaBinsList={metaBinsList} />
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="Inactive"
        >
          <UnActiveBins metaBinsList={metaBinsList} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default MetaBinsStatus;
