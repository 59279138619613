import React, { useEffect } from "react";
import DashboardStatistics from "../components/analytics/DashboardStatistics";
import MetaBinsStatus from "../components/analytics/MetaBinsStatus";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsAction,
  getAnalyticsOverviewAction,
  getUserEngagementData
} from "../redux/action/MetaBinAction";
import UserEngagement from "../components/analytics/UserEngagement";
import MainChart from "../components/Charts/MainChart";
import Loading from "./Loading";
import RealTimeUserActivity from "../components/analytics/RealTimeUserActivity";
import DetailedCollectionReport from "../components/Charts/DetailedCollectionReport";

function Analytics() {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const { analytics, analyticsOverview, loading } = useSelector(
    (state) => state.analyticsStore
  );

  useEffect(() => {
    dispatch(getAnalyticsAction());
    dispatch(getAnalyticsOverviewAction());
    dispatch(getUserEngagementData());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DashboardStatistics
            analytics={{
              itemsCollected: analytics?.itemsCollected,
              numberOfUsers: analytics?.numberOfUsers,
              weightCollected: analytics?.weightCollected,
              weightRecycled: analytics?.weightRecycled,
              total: analytics?.total,
              userGrowth: analyticsOverview?.userGrowth
            }}
          />

          {/* <div
            className={isTablet ? "flex column gap-1 " : "flex row "}
            style={{ height: "90vh" }}
          >
            <div className="col s12 box  ">
              <HeatMap />
            </div>

            <div className="col s4"></div>
          </div> */}

          <div className={isTablet ? "flex column gap-1 mt-1 " : "flex row "}>
            <div className="col s6">
              <MetaBinsStatus />
            </div>
            <div className="col s6">
              <UserEngagement />
            </div>
          </div>

          <div>
            <DetailedCollectionReport />
          </div>

          <div className={"mb-2  mt-2"}>
            <RealTimeUserActivity />
          </div>

          <MainChart />
        </>
      )}
    </div>
  );
}

export default Analytics;
