import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBarcode } from "../redux/action/MetaBinAction";
import { toast } from "react-toastify";

const AddBarcode = () => {
  // {
  //     "barcode": "8888196173324",
  //     "volume": "500",
  //     "productName":"Jasmine Green Tea",
  //     "productBrand": "Jasmine Green Tea",
  //     "prouctParentCompany": "Pokka Private Limited",
  //     "productMaterialType": "plastic",
  //     "scrap":
  // }

  const [barcode, setBarcode] = useState("");
  const [volume, setVolume] = useState("");
  const [productName, setProductName] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [prouctParentCompany, setProuctParentCompany] = useState("");
  const [productMaterialType, setProductMaterialType] = useState("");
  const [scrap, setScrap] = useState("6269b572672d2875f8115972");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const sumbit = (e) => {
    e.preventDefault();
    const data = {
      barcode,
      volume,
      productName,
      productBrand,
      prouctParentCompany,
      productMaterialType,
      scrap,
    };
    setLoading(true);

    dispatch(
      addBarcode(data, () => {
        setLoading(false);
        setBarcode("");
        setVolume("");
        setProductName("");
        setProductBrand("");
        setProuctParentCompany("");
        setProductMaterialType("");
        toast.success("Barcode Added Successfully");
        window.M.Modal.getInstance(
          document.getElementById("add-barcode-modal")
        ).close();
        document.body.style.overflow = "auto";
      })
    );
  };

  return (
    <form onSubmit={sumbit}>
      <h5 className="bold">Add Barcode</h5>

      <div className="flex gap-1">
        <div className="mt-1 full-width">
          <label className="">Enter Barcode</label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="number"
              placeholder="Eg. 0123456789012"
              onChange={(e) => setBarcode(e.target.value)}
              value={barcode}
              min={"0"}
              required
            />
          </div>
        </div>

        <div className="mt-1 full-width">
          <label className="">Enter Volume (in ml)</label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="number"
              placeholder="Eg. 500ml"
              onChange={(e) => setVolume(e.target.value)}
              value={volume}
              min="0"
              required
            />
          </div>
        </div>
      </div>

      <div className="flex gap-1">
        <div className="mt-1 full-width">
          <label className="">Enter Product Name</label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="text"
              placeholder="Eg. Pepsi Cola"
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
              required
            />
          </div>
        </div>

        <div className="mt-1 full-width">
          <label className="">Enter Product Brand</label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="text"
              placeholder="Eg. Pepsi"
              onChange={(e) => setProductBrand(e.target.value)}
              value={productBrand}
              required
            />
          </div>
        </div>
      </div>

      <div className="flex gap-1">
        <div className="mt-1 full-width">
          <label className="">Enter Product Parent Company</label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="text"
              placeholder="Eg. Pepsico"
              onChange={(e) => setProuctParentCompany(e.target.value)}
              value={prouctParentCompany}
              required
            />
          </div>
        </div>

        <div className="mt-1 full-width">
          <label>Select Material Type</label>
          <div className="row  ">
            <select
              className="input-style full-width browser-default "
              onChange={(e) => setProductMaterialType(e.target.value)}
              value={productMaterialType}
              required
            >
              <option value={""}>--select--</option>
              <option value={"plastic"}>plastic</option>
              <option value={"metal"}>metal</option>
            </select>
          </div>
        </div>
      </div>

      <button className="btn" type="submit" disabled={loading}>
        Add Barcode
      </button>
    </form>
  );
};

export default AddBarcode;
