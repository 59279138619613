import React, { useState, useEffect } from "react";
import Maps from "../components/maps/Maps";
import SingleBin from "../components/SingleBin";
import { useSelector, useDispatch } from "react-redux";
import { getAllMetaBinsAction } from "../redux/action/MetaBinAction";
import Loading from "./Loading";

function Map() {
  const dispatch = useDispatch();
  const { metaBinsList, loading } = useSelector((state) => state.allMetaBins);

  const [openBinDetails, setOpenBinDetails] = useState({
    binId: null,
    open: false
  });

  useEffect(() => {
    if (metaBinsList) {
      setOpenBinDetails({ ...openBinDetails, binId: metaBinsList[0]?._id });
    }
  }, [metaBinsList]);
  useEffect(() => {
    dispatch(getAllMetaBinsAction());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <div style={{ width: "100%" }}>
          <Maps
            openBinDetails={openBinDetails}
            setOpenBinDetails={setOpenBinDetails}
            metaBinsList={metaBinsList}
          />
        </div>
        <div
          className="ml-4 flex"
          style={{
            height: "100%",
            position: "fixed",
            right: 0,
            top: "6vh",
            overflowY: "auto",
            marginBottom: "2rem"
          }}
        >
          <div
            className=" flex align-center  lighten-2 justify-center mt-5 box hover d-depth-2 "
            style={{
              height: "4rem ",
              width: "4rem",
              borderRadius: "8px 0 0 8px "
            }}
            onClick={() =>
              setOpenBinDetails({
                ...openBinDetails,
                open: !openBinDetails.open
              })
            }
          >
            {!openBinDetails.open ? (
              <span
                className="material-symbols-outlined big-icon"
                style={{ color: "#6f2da8" }}
              >
                close
              </span>
            ) : (
              <span
                className="material-symbols-outlined big-icon"
                style={{ color: "#6f2da8" }}
              >
                close
              </span>
            )}
          </div>
          {openBinDetails.open && <SingleBin openBinDetails={openBinDetails} />}
        </div>
      </div>
    </>
  );
}

export default Map;
