import React from "react";
import { Card } from "react-materialize";
import WalletManagementTransactionHistory from "../components/payments/WalletManagementTransactionHistory";

function Wallet() {
  return (
    <div className="mt-1  p-1">
      <div className=" flex align-center gap-1 p-1  metabin-card ">
        <span className="material-symbols-outlined purple p-1 circle lighten-5  ">
          account_balance_wallet
        </span>
        <span>
          <p>Available Balance</p>
          <b className="normal-size">00.00</b>
        </span>
      </div>
      <WalletManagementTransactionHistory />
    </div>
  );
}

export default Wallet;
