import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Aside({ openAside, setOpenAside }) {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenAside();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openAside, setOpenAside]);
  return (
    <aside
      className={openAside ? "aside-list-open" : " aside-list"}
      ref={sidebarRef}
    >
      <ul className="aside-items-list">
        <p className="ml-1 small-text blue-grey-text mt-1 mb-1">
          {openAside && "MASTER"}
        </p>

        <li
          className={
            location.pathname === "/"
              ? "flex aside-item-active aside-item  "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined  ">dashboard</span>
          &nbsp;
          {openAside && <p className="normal-size">Dashboard</p>}
        </li>

        <li
          className={
            location.pathname === "/map"
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/map");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined">map</span>&nbsp;
          {openAside && <p className="normal-size">Map</p>}
        </li>

        <li
          className={
            location.pathname === "/payments"
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/payments");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined">
            account_balance_wallet
          </span>
          &nbsp;
          {openAside && <p className="normal-size">Payments</p>}
        </li>

        <li
          className={
            location.pathname === "/bins" ||
            location.pathname?.includes("/bin-details/")
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/bins");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined">delete</span>
          &nbsp;
          {openAside && <p className="normal-size">Bins</p>}
        </li>

        <li
          className={
            location.pathname === "/accounts"
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/accounts");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined">person</span>
          &nbsp;
          {openAside && <p className="normal-size">Accounts</p>}
        </li>

        <li
          className={
            location.pathname === "/analytics"
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => {
            navigate("/analytics");
            setOpenAside(false);
          }}
        >
          <span className="material-symbols-outlined">signal_cellular_alt</span>
          &nbsp;
          {openAside && <p className="normal-size">Analytics</p>}
        </li>

        {/*  <li
          className={
            location.pathname === "/vouchers"
              ? "flex aside-item-active aside-item "
              : " flex aside-item"
          }
          onClick={() => navigate("/vouchers")}
        >
          <span className="material-symbols-outlined">redeem</span>
          &nbsp;
          {openAside && <p className="normal-size">Vouchers</p>}
        </li>*/}

        <div className="full-width mt-3 ">
          <p className="ml-1 small-text blue-grey-text">
            {openAside && "SUPPORTS"}
          </p>

          <li
            className={
              location.pathname === "/settings"
                ? "flex aside-item-active aside-item "
                : " flex aside-item"
            }
            onClick={() => {
              navigate("/settings");
              setOpenAside(false);
            }}
          >
            <span className="material-symbols-outlined">settings</span>
            &nbsp;
            {openAside && <p className="normal-size">Settings</p>}
          </li>

          <li
            className={
              location.pathname === "/help"
                ? "flex aside-item-active aside-item "
                : " flex aside-item"
            }
            onClick={() => {
              navigate("/help");
              setOpenAside(false);
            }}
          >
            <span className="material-symbols-outlined">info</span>
            &nbsp;
            {openAside && <p className="normal-size">Help</p>}
          </li>
        </div>
      </ul>

      {openAside ? (
        <div className="flex full-width justify-center column align-center ">
          <p className="grey-text small-text center " style={{ width: "90%" }}>
            All copyrights reserved {new Date().getFullYear()} by{" "}
          </p>
          <a
            className="small-text cercle-purple-text"
            href="http://cerclex.com/"
          >
            &nbsp; Infinite Cercle (Cercle X)
          </a>
        </div>
      ) : (
        <div>
          <img src="../images/cerclex-logo.png" style={{ width: "50px" }} />
        </div>
      )}
    </aside>
  );
}

const styles = {
  app_name: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "38px"
  }
};

export default Aside;
