import React from "react";
import { useMediaQuery } from "react-responsive";

function ControlCenter() {
  const isMobile = useMediaQuery({ query: "(max-width: 810px)" });

  return (
    <div className=" flex column mt-2 p-1">
      <div className="flex align-center justify-center mv-2 ">
        <b className="normal-size">
          Empower Your Recycling Program with the metaBin Admin Dashboard
        </b>
      </div>

      <div
        className={
          !isMobile
            ? "flex algin-center justify-center gap-2 "
            : " align-center flex column "
        }
      >
        <span style={{ width: isMobile ? " 100%" : "35vw" }}>
          <b className="normal-size">Having Control on ever Metabins</b>
          <p className="mt-1 grey-text ">
            {" "}
            Your Admin Dashboard with Real-time Metabin Status. Stay informed
            about Top Bins, Active Bins, Inactive Bins, and Filled Bins,
            enabling you to effectively manage and control the entire Metabins
            network from a centralized dashboard.
          </p>
        </span>
        <img src="../images/metabin-status.png" style={{ width: "400px" }} />
      </div>

      <div
        className={
          !isMobile
            ? "flex algin-center justify-center gap-2  mt-2"
            : " align-center flex column "
        }
      >
        <img
          src="../images/app-live.png"
          style={{ width: "450px", height: "300px" }}
        />

        <span style={{ width: isMobile ? " 100%" : "35vw" }}>
          <b className="normal-size">Realtime update about your Metabins </b>
          <p className="mt-1 grey-text ">
            {" "}
            Monitor the performance of each Metabin in the network. Stay
            informed about filled data, maintenance needs, and battery
            percentage, ensuring smooth operations and optimal efficiency.
          </p>
        </span>
      </div>

      <div
        className={
          !isMobile
            ? "flex algin-center justify-center gap-2 mt-2 "
            : " align-center flex column mt-2 "
        }
      >
        <span style={{ width: isMobile ? " 100%" : "35vw" }}>
          <b className="normal-size">Analytics and Reports</b>
          <p className="mt-1 grey-text ">
            {" "}
            Analytics and Reports: Unlock valuable insights with comprehensive
            analytics and customizable reports. Analyze key performance
            indicators, track recycling trends, and measure the impact of
            sustainability initiatives.
          </p>
        </span>
        <img src="../images/metabin-alaytics.png" style={{ width: "400px" }} />
      </div>
    </div>
  );
}

export default ControlCenter;
