import React from "react";

function AccountsTable({ accounts }) {
  return (
    <div>
      {" "}
      <table className="striped  ">
        <thead>
          <tr>
            <th className="primary-text">#</th>
            <th className="primary-text">Name</th>
            <th className="primary-text">Email</th>
            <th className="primary-text">Phone Number</th>
          </tr>
        </thead>

        <tbody>
          {accounts?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td className="capitalize">{item.account.name}</td>
                <td>{item.account.email}</td>
                <td>{item.account.phoneNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AccountsTable;
