export const getAllMetaBinsReducer = (
  state = { loading: false, metaBinsList: [] },
  action
) => {
  switch (action.type) {
    case "GET_ALL_METABIN":
      return { ...state, loading: true };
    case "GOT_ALL_METABIN":
      return {
        ...state,
        loading: false,
        metaBinsList: action.payload.sort((a) => a.status === "active"),
      };
    case "CANNOT_GET_ALL_METABIN":
      return { ...state, loading: false };

    case "ADDING_BARCODE":
      return { ...state, loading: true };

    case "ADDED_BARCODE":
      return {
        ...state,
        loading: false,
      };
    case "CANNOT_ADD_BARCODE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleMetaBinReducer = (
  state = { loading: false, singleBin: {} },
  action
) => {
  switch (action.type) {
    case "GET_SINGLE_METABIN":
      return { ...state, loading: true };
    case "GOT_SINGLEMETABIN":
      return { ...state, loading: false, singleBin: action.payload };
    case "CANNOT_GET_SINGLEMETABIN":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getProfileReducer = (
  state = {
    loading: false,
    profile: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_PROFILE":
      return { ...state, loading: false };
    case "GOT_PROFILE":
      return { ...state, loading: false, profile: action.payload };
    case "CANNOT_GET_PROFILE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getNotificationsReducer = (
  state = { loading: false, notifications: [] },
  action
) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS":
      return { ...state, loading: true };
    case "GOT_NOTIFICATIONS":
      return { ...state, loading: false, notifications: action.payload };
    case "CANNOT_GET_NOTIFICATIONS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAnalyticsReducer = (
  state = { loading: false, analytics: {}, analyticsOverview: {} },
  action
) => {
  switch (action.type) {
    case "GETING_ANALYTICS":
      return { ...state, loading: true };
    case "GOT_ANALYTICS":
      return { ...state, loading: false, analytics: action.payload };

    case "GOT_ANALYTICS_OVERVIEW":
      return {
        ...state,
        loading: false,
        analyticsOverview: action.payload,
      };
    case "CANNOT_GET_ANALYTICS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleMetaBinSessionReducer = (
  state = { loading: false, metaBinSessions: [] },
  action
) => {
  switch (action.type) {
    case "GETING_SINGLE_BIN_SESSIONS":
      return { ...state, loading: true };
    case "GOT_SINGLE_BIN_SESSIONS":
      return { ...state, loading: false, metaBinSessions: action.payload };
    case "CANNOT_GET_SINGLE_BIN_SESSIONS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getProductsCollectedByDateReducer = (
  state = { productsCollected: [], loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_PRODUCTS_BY_DATE":
      return { ...state, loading: true };
    case "GOT_PRODUCTS_BY_DATE":
      return { ...state, loading: false, productsCollected: action.payload };
    case "CANNOT_GET_PRODUCTS_BY_DATE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getProductsSessionReducer = (
  state = { loading: false, productsSession: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_PRODUCTS_SESSION":
      return { ...state, loading: true };
    case "GOT_PRODUCTS_SESSION":
      return { ...state, loading: false, productsSession: action.payload };
    case "CANNOT_GET_PRODUCTS_SESSION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleBinSessionReducer = (
  state = { sesions: [], loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_SINGLE_BIN_SESSION":
      return { ...state, loading: true };
    case "GOT_METABIN_SESSION":
      return { ...state, loading: false, sesions: action.payload };
    case "CANNOT_GET_SESSIONS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSessionAnalyticsReducer = (
  state = { sessionsLast2Month: "", sessionsLastMonth: [], loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_SESSIONS":
      return { ...state, loading: true };
    case "GOT_SESSION_FOR_LAST2_MONTH":
      return { ...state, sessionsLast2Month: action.payloadForLast2Month };
    case "GOT_SESSION_FOR_LASTMONTH":
      return {
        ...state,
        loading: false,
        sessionsLastMonth: action.payloadForLastMonth,
      };
    case "CANNOT_GET_SESSION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getProductsCollectedAnalytivsReducer = (
  state = { loading: false, productsLastMonth: [], productsLastTwoMonth: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_PRODUCTS":
      return { ...state, loading: true };
    case "GOT_PRODUCTS_COLLECTED_LAST_60DAYS":
      return { ...state, productsLastTwoMonth: action.payloadForLast2Month };
    case "GOT_PRODUCTS_COLLECTED_LAST30DAYS":
      return {
        ...state,
        loading: false,
        productsLastMonth: action.payloadForLastMonth,
      };
    case "CANNOT_GET_PRODUCTS_COLLECTED":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getMapDataReducer = (
  state = { mapData: [], mapDataloading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_MAP_DATA":
      return { ...state, mapDataloading: true };
    case "GOT_MAP_DATA":
      return { ...state, mapDataloading: false, mapData: action.payload };
    case "CANNOT_GET_MAP_DATA":
      return { ...state, mapDataloading: false };
    default:
      return state;
  }
};

export const getBinFeedBackReducer = (
  state = { loading: false, feedBack: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_FEEDBACK":
      return { ...state, loading: true };
    case "GOT_FEEDBACK":
      return { ...state, loading: false, feedBack: action.payload };
    case "CANNOT_GET_FEEDBACK":
      return { ...state, loading: false };
    default:
      return state;
  }
};
export const getTopUsersReducer = (
  state = { loading: false, topUsers: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_TOP_USERS":
      return { ...state, loading: true };
    case "GOT_TOP_USERS":
      return { ...state, loading: false, topUsers: action.payload };
    case "CANNOT_GET_TOP_USERS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getOrganizationReducer = (
  state = { loading: false, orgainzation: {}, inviting: false },
  action
) => {
  switch (action.type) {
    case "GETTING_ORGANIZATION":
      return { ...state, loading: true };
    case "GOT_ORGANIZATION":
      return { ...state, loading: false, orgainzation: action.payload };
    case "CANNOT_GET_ORGANIZATION":
      return { ...state, loading: false };

    case "INVITING_ACCOUNT":
      return { ...state, inviting: true };

    case "INVITED_ACCOUNT":
      return { ...state, inviting: false };

    case "CANNOT_INVITE_ACCOUNT":
      return { ...state, inviting: false };
      
    default:
      return state;
  }
};

export const getTopoMapDataReducer = (
  state = {
    topoMapDataLoading: false,
    topoMapData: {},
    center: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_TOPO_JSON_MAP":
      return { ...state, topoMapDataLoading: true };
    case "GOT_TOPO_JSON_MAP":
      return {
        ...state,
        topoMapDataLoading: false,
        topoMapData: action.payload,
        center: action.center,
      };
    case "CANNOT_GET_TOPO_JSON_MAP":
      return { ...state, topoMapDataLoading: false };
    default:
      return state;
  }
};

export const getUserEngagementDataReducer = (
  state = { loading: false, userEngagement: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_USER_ENGAGEMENT_DATA":
      return { ...state, loading: true };
    case "GOT_USER_ENGAGEMENT_DATA":
      return { ...state, loading: false, userEngagement: action.payload };
    case "CANNOT_GET_USER_ENGAGEMENT_DATA":
      return { ...state, loading: false };
    default:
      return state;
  }
};
