import React from "react";
import { Switch } from "react-materialize";

function NotificationSettings() {
  return (
    <div className="p-1">
      <p className="bold" >What you will receive?</p>
      <p className="mb-1 grey-text border-bottom ">
        CercleX may still send you important notification about your account and
        content outside your preferred notification settings.
      </p>

      <p className="mt-2 bold">Updates</p>
      <p className="grey-text">
        You will receive notifications for requested and remaining qr codes,
        total qr scanned on each day etc.,
      </p>

      <form className="mt-2">
        <div className="flex align-center space-between">
          <div className="flex align-center">
            <span className="material-symbols-outlined primary-text ">
              notifications
            </span>
            &nbsp;
            <p>Push Notifications</p>
          </div>
          <Switch id="Switch-20" />
        </div>

        <div className="flex align-center space-between mt-1">
          <div className="flex align-center">
            <span className="material-symbols-outlined primary-text">mail</span>
            &nbsp;
            <h6>Email</h6>
          </div>
          <Switch id="Switch-200" />
        </div>

        <div className="mt-2 flex justify-end gap-1 ">
          <div className="btn-outline">Cancel</div>
          <button className="btn" style={{ width: "10rem" }} disabled >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default NotificationSettings;
