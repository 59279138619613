import React from "react";

function WalletManagementTransactionHistory() {
  return (
    <div className="mt-2">
      <b className="normal-size  ">Wallet Management:</b>

      <span className="flex align-center mt-1 space-between ">
        <b>Transaction History</b>
        <button className="btn-outline" disabled>
          View All
        </button>
      </span>
      <div className="box  " style={{ marginTop: "10px" }}>
        <table className="striped">
          <thead>
            <tr>
              <th className="primary-text">#</th>
              <th className="primary-text">Date</th>
              <th className="primary-text">Transactions</th>
              <th className="primary-text">Amount</th>
              <th className="primary-text">Status</th>
              <th className="primary-text">Invoice</th>
            </tr>
          </thead>

          {/*  <tbody>
            <tr className="small-text">
              <td>1.</td>
              <td className="capitalize">21/06/2023</td>
              <td> Add balance to Wallet.</td>
              <td>4000</td>
              <td>
                <b className="successful-status">Completed</b>
              </td>
              <td>
                <button className="download-btn">
                  {" "}
                  <span className="material-symbols-outlined small-text ">
                    download
                  </span>
                  &nbsp;Download
                </button>
              </td>
            </tr>
            <tr className="small-text">
              <td>1.</td>
              <td className="capitalize">20/06/2023</td>
              <td> Add balance to Wallet.</td>
              <td>1500</td>
              <td>
                <b className="successful-status">Completed</b>
              </td>
              <td>
                <button className="download-btn">
                  {" "}
                  <span className="material-symbols-outlined small-text ">
                    download
                  </span>
                  &nbsp;Download
                </button>
              </td>
            </tr>

            <tr className="small-text">
              <td>1.</td>
              <td className="capitalize">12/06/2023</td>
              <td> Add balance to Wallet.</td>
              <td>2500</td>
              <td>
                <b className="successful-status">Completed</b>
              </td>
              <td>
                <button className="download-btn">
                  {" "}
                  <span className="material-symbols-outlined small-text ">
                    download
                  </span>
                  &nbsp;Download
                </button>
              </td>
            </tr>
            <tr className="small-text">
              <td>1.</td>
              <td className="capitalize">10/06/2023</td>
              <td> Add balance to Wallet.</td>
              <td>1000</td>
              <td>
                <b className="successful-status">Completed</b>
              </td>
              <td>
                <button className="download-btn">
                  {" "}
                  <span className="material-symbols-outlined small-text ">
                    download
                  </span>
                  &nbsp;Download
                </button>
              </td>
            </tr>
            <tr className="small-text">
              <td>1.</td>
              <td className="capitalize">02/05/2023</td>
              <td> Add balance to Wallet.</td>
              <td>400</td>
              <td>
                <b className="successful-status">Completed</b>
              </td>
              <td>
                <button className="download-btn">
                  {" "}
                  <span className="material-symbols-outlined small-text ">
                    download
                  </span>
                  &nbsp;Download
                </button>
              </td>
            </tr>
       </tbody>*/}
        </table>
        <div
          className="flex justify-center grey-text align-center column "
          style={{ minHeight: "200px" }}
        >
          <span class="material-symbols-outlined" style={{ fontSize: "48px" }}>
            contract
          </span>
          <b>Sorry, No transactions found.</b>
        </div>
      </div>
    </div>
  );
}

export default WalletManagementTransactionHistory;
