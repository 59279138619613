import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid
} from "recharts";
import { startOfMonth, endOfMonth } from "date-fns";
import { useEffect } from "react";
import { getCollectionReportData } from "../../redux/action/MetaBinAction";
import { useDispatch } from "react-redux";
import { Preloader } from "react-materialize";

const DetailedCollectionReport = () => {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    dispatch(getCollectionReportData(startDate, endDate, setLoading, setData));
  }, [dispatch, endDate, startDate]);

  return (
    <div className="p-1 metabin-card">
      <div className="flex space-between align-center pl-1 pr-1 mb-2 ">
        <div>
          <b>Items Collected By Your Metabins Statistics</b>
          <span className="ml-1 grey-text">
            ({startDate?.toDateString()} - {endDate?.toDateString()})
          </span>
        </div>

        <div className="flex align-center">
          <Preloader size="small" active={loading} className="mr-1" />
          <div className="chart-date-select  flex align-center gap-1  ">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className="browser-default input-text "
              dateFormat="dd/MM/yyyy"
            />
            <span className="material-symbols-outlined cercle-purple-text ">
              calendar_month
            </span>
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart width={500} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="productName" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="count"
            fill="#ebe0f4"
            stroke="#6f2da8"
            strokeWidth={1}
          />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DetailedCollectionReport;
