import React from "react";
import TimeLine from "../layout/TimeLine";
import { useMediaQuery } from "react-responsive";

function PickupTimeLine() {
  return (
    <div className="container">
      <div className="flex space-around full-width box align-center mb-1 p-1 ">
        <p>Gandhipuram Busstand</p>
        <p>Plastic Bin </p>
        <p>1 Tonne</p>
        <p className="redeemed">Recycled</p>
      </div>
      <div className="flex full-width ">
        <div
          className=" p-1 box time-line-box  mb-1"
          style={{ width: "48%", borderColor: "#db2518" }}
        >
          <div className="space-between align-center flex hover mb-1  ">
            <b>Pickup accepted</b>
            <span className="material-symbols-outlined primary">
              keyboard_double_arrow_up
            </span>
          </div>
          <p>
            Driver Raj and Kumar arrived at requested location and completed the
            load at 2 vechicles
          </p>
        </div>

        <TimeLine
          color={"#34A853"}
          icon={<span className="material-symbols-outlined">more_up</span>}
        />
        <div className="pt-1 pl-1  " style={{ width: "48%" }}>
          <p>25 Nov 2022</p>
        </div>
      </div>

      <div className="flex full-width ">
        <div className="pt-1 pl-1 flex justify-end " style={{ width: "48%" }}>
          <p>25 Nov 2022</p>
        </div>
        <TimeLine
          color={"#34A853"}
          icon={<span className="material-symbols-outlined">front_loader</span>}
        />
        <div
          className=" p-1 box time-line-box  mb-1"
          style={{ width: "48%", borderColor: "#052df5" }}
        >
          <div className="space-between align-center flex hover mb-1 ">
            <b>Pickup accepted</b>
            <span className="material-symbols-outlined primary">
              keyboard_double_arrow_up
            </span>
          </div>

          <p>
            Driver Raj and Kumar arrived at requested location and completed the
            load at 2 vechicles
          </p>
        </div>
      </div>

      <div className="flex full-width ">
        <div
          className=" p-1 box time-line-box  mb-1"
          style={{ width: "48%", borderColor: "#16f057" }}
        >
          <div className="space-between align-center flex hover mb-1 ">
            <b>Pickup accepted</b>
            <span className="material-symbols-outlined primary">
              keyboard_double_arrow_up
            </span>
          </div>

          <p>
            Driver Raj and Kumar arrived at requested location and completed the
            load at 2 vechicles
          </p>
        </div>
        <TimeLine
          color={"#34A853"}
          icon={
            <span className="material-symbols-outlined">sentiment_satisfied</span>
          }
        />
        <div className="pt-1 pl-1  " style={{ width: "48%" }}>
          <p>25 Nov 2022</p>
        </div>
      </div>

      <div className="flex full-width ">
        <div className="pt-1 pl-1 flex justify-end " style={{ width: "48%" }}>
          <p>25 Nov 2022</p>
        </div>
        <TimeLine
          color={"#34A853"}
          icon={<span className="material-symbols-outlined">assignment_add</span>}
        />
        <div
          className=" p-1 box time-line-box  mb-1"
          style={{ width: "48%", borderColor: "#b009ed" }}
        >
          <div className="space-between align-center flex hover mb-1 ">
            <b>Pickup accepted</b>
            <span className="material-symbols-outlined primary">
              keyboard_double_arrow_up
            </span>
          </div>
          <p>
            Driver Raj and Kumar arrived at requested location and completed the
            load at 2 vechicles
          </p>
        </div>
      </div>
    </div>
  );
}

export default PickupTimeLine;
