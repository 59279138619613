import React from "react";

function ChangePassword() {
  return (
    <div className="full-width p-1  ">
      <form>
        <div className="search-input flex align-center full-width ">
          <span className="material-symbols-outlined grey-text ">enhanced_encryption</span>
          <input
            className="browser-default input-text"
            type="text"
            placeholder="Old Password"
          />
        </div>

        <div className="search-input flex align-center full-width mv-1 ">
          <span className="material-symbols-outlined grey-text ">enhanced_encryption</span>
          <input
            className="browser-default input-text"
            type="text"
            placeholder="New Password"
          />
        </div>

        <div className="search-input flex align-center full-width ">
          <span className="material-symbols-outlined grey-text ">enhanced_encryption</span>
          <input
            className="browser-default input-text"
            type="text"
            placeholder="Confirm New Password"
          />
        </div>

        <div className="mt-2 flex justify-end gap-1 ">
          <div className="btn-outline">Cancel</div>
          <button className="btn" style={{ width: "10rem" }} disabled >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
