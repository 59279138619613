import React, { useEffect, useState } from "react";
import PickupHistoryTable from "../components/tables/PickupHistoryTable";
import BinDetails from "../components/singleBin/BinDetails";
import SingleBinChart from "../components/singleBin/SingleBinChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getsingleMetaBinAction,
  editBinLocation,
  resetMetabinData,
} from "../redux/action/MetaBinAction";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import UsersFeedBack from "../components/analytics/UsersFeedBack";
import { useNavigate } from "react-router-dom";
import CollectionReportChart from "../components/Charts/CollectionReportChart";
import { Button, Modal } from "react-materialize";
import EditLocation from "../components/EditLocation";
import { toast } from "react-toastify";
import TopUsers from "../components/analytics/TopUsers";
import MaterialTypeCollection from "../components/Charts/MaterialTypeCollection";

function SingleBinDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleBin, loading } = useSelector((state) => state.singleMetaBin);
  const { orgainzation } = useSelector((state) => state.organizationStore);

  const [values, setValues] = React.useState({
    location: {
      longitude: "",
      latitude: "",
      address: "",
      detailedAddress: "",
    },
  });

  console.log(values);

  function editLocation() {
    const data = {
      latitude: values.location.latitude,
      longitude: values.location.longitude,
      address: values.location.address,
      detailedAddress: values.location.detailedAddress,
    };

    console.log(data);

    dispatch(
      editBinLocation(data, id, () => {
        const locationModal = document.getElementById("edit_location");
        const instance = window.M.Modal.getInstance(locationModal);
        instance.close();
        toast.success("Location Added successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setValues({
          location: {
            address: "",
            lat: "",
            lng: "",
          },
        });
      })
    );
  }

  useEffect(() => {
    dispatch(getsingleMetaBinAction(id));
  }, [dispatch, id]);

  if (loading) return <Loading />;

  return (
    <>
      <header className="flex space-between">
        <div className="flex align-center  mb-2">
          <span
            className="material-symbols-outlined hover "
            onClick={() => navigate(-1)}
            role="button"
          >
            keyboard_backspace
          </span>
          <b className="normal-size ">&nbsp;MetaBin Details</b>
        </div>

        <div className="flex align-center gap-1 ">
          <Modal
            id="edit_location"
            header="Edit Bin Location"
            trigger={
              <Button className="btn-outline white" node="button">
                <div className="flex gap-1 flex-end  ">
                  <img
                    src="../images/edit-location.gif"
                    style={{
                      width: "35px",
                      height: "30px",
                      paddingTop: "2px",
                    }}
                    alt="edit location"
                  />
                  <b>Edit location</b>
                </div>
              </Button>
            }
          >
            <EditLocation
              editLocation={editLocation}
              values={values}
              setValues={setValues}
              loading={loading}
            />
          </Modal>

          {orgainzation?._id === "65128db3c22cb6f8f9cd85b2" && (
            <Modal trigger={<Button>Reset Data</Button>} actions={[]}>
              <p>
                Are you sure you want to clear all data collected by metabins
                owned by you?
              </p>

              <div className="flex gap-1">
                <Button
                  tooltip="Clear all data collected by this bin"
                  className="btn"
                  onClick={() => {
                    dispatch(
                      resetMetabinData(singleBin._id, () => {
                        toast.success(
                          "All the data associated with this bin has been cleared"
                        );
                      })
                    );
                  }}
                >
                  Reset Data
                </Button>

                <Button className="btn white cercle-purple-text" modal="close">
                  Cancel
                </Button>
              </div>
            </Modal>
          )}
        </div>
      </header>
      <div className="flex gap-2 flex-wrap mb-1">
        <BinDetails binDetails={singleBin} />

        <CollectionReportChart
          data={singleBin?.collectionReport ? singleBin?.collectionReport : []}
        />
      </div>

      <TopUsers binId={id} />

      <UsersFeedBack binId={id} />

      <div className="mb-1">
        <PickupHistoryTable />
      </div>

      <SingleBinChart />
    </>
  );
}

export default SingleBinDetails;
