import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBinFeedBacAction,
  getTopUsers,
} from "../../redux/action/MetaBinAction";
import { formatDate } from "../help/helper";
import { useNavigate } from "react-router-dom";
import { Card } from "react-materialize";

function TopUsers({ binId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { topUsers } = useSelector((state) => state.topUsers);

  useEffect(() => {
    dispatch(getTopUsers(binId));
  }, [binId, dispatch]);

  return (
    <Card style={{ height: "100%" }} className="p-1">
      <div className="flex align-center space-between ">
        <b>Top Metabin Users</b>
        <div
          role="button"
          className="btn-outline"
          onClick={() => navigate(`/topUsers/${binId}`)}
        >
          View All
        </div>
      </div>
      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">No.</th>
            <th className="primary-text">User</th>
            <th className="primary-text">Count</th>
          </tr>
        </thead>

        <tbody>
          {topUsers.map((item, index) => {
            if (index < 5)
              return (
                <tr className="semi-small-text" key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <p>{item.account.name}</p>
                    <label className="small-text">+{item.account.phoneNumber}</label>
                  </td>
                  <td>{item.count}</td>
                </tr>
              );
          })}
        </tbody>
      </table>
      {topUsers.length === 0 && (
        <div className="flex full-width p-1 column align-center justify-center mt-2 mb-2 ">
          <span className="material-symbols-outlined large grey-text ">
            comment
          </span>
          <p className="grey-text small-text">
            There is no topUsers right now.
          </p>
        </div>
      )}
    </Card>
  );
}

export default TopUsers;
