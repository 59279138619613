import React from "react";
import { Table } from "react-materialize";
import { useNavigate } from "react-router-dom";

function ActiveBins({ metaBinsList }) {
  const navigate = useNavigate();

  const active = metaBinsList.filter((item) => item.status === "active");

  if (active.length === 0)
    return (
      <div className="white flex align-center justify-center">
        <p>No Active Bins Found At The Moment</p>
      </div>
    );

  return (
    <div className="white">
      <Table className="striped">
        <thead>
          <tr>
            <th className="primary-text">#</th>
            <th className="primary-text">type</th>
            <th className="primary-text">fullness</th>
            <th className="primary-text">location</th>
            <th className="primary-text">action</th>
          </tr>
        </thead>

        <tbody>
          {active.map((item, index) => {
            if (index <= 3) {
              return (
                <tr className="small-text" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.material}</td>
                  <td>{item.fillLevel}%</td>
                  <td>{item.address}</td>
                  <td>
                    <span
                      onClick={() => navigate(`/bin-details/${item._id}`)}
                      className="material-symbols-outlined primary-text normal-size hover"
                    >
                      visibility
                    </span>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ActiveBins;
