import React from "react";
import { Card, Tab, Tabs } from "react-materialize";
import CO2EmissionAvoided from "./report/CO2EmissionAvoided";
import SaveWater from "./report/SaveWater";
import TreeSaved from "./report/TreeSaved";
import Co2Footprint from "./report/Co2Footprint";

function SustainabilityReports() {
  return (
    <div className="metabin-card p-1 full-height sustainability-report-chart">
      <b className="normal-size">Sustainability Reports</b>
      <Tabs>
        <Tab idx="1" title="CO2 Emission Avoided">
          <CO2EmissionAvoided />
        </Tab>

        <Tab
          idx="2"
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="Water Saved"
        >
          <SaveWater />
        </Tab>
        <Tab
          idx="3"
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="CO2 Footprint"
        >
          <Co2Footprint />
        </Tab>
        <Tab
          idx="4"
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="Trees Saved"
        >
          <TreeSaved />
        </Tab>
      </Tabs>
    </div>
  );
}

export default SustainabilityReports;
