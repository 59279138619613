import React from "react";
import {
  Collapsible,
  CollapsibleItem,
  Modal,
  Textarea
} from "react-materialize";
import { useMediaQuery } from "react-responsive";

function FAQ() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  return (
    <div className="container mt-2 ">
      <div className="full-width  mr-2 ">
        <div className="flex align-center space-between  ">
          <div className="flex align-center ">
            {!isTablet && (
              <span className="material-symbols-outlined primary-text ">
                help
              </span>
            )}
            &nbsp;
            <b>General Questions</b>
          </div>
          <Modal
            bottomSheet={false}
            fixedFooter={false}
            id="Modal-10"
            open={false}
            trigger={
              <button className="  btn">
                <p>Ask your questions</p>
              </button>
            }
          >
            <form>
              <b className="bold">Message Us:</b>
              <Textarea
                type="text"
                placeholder="Write us a message..."
                className=" text-area"
              />
              <div className="flex justify-end  ">
                <div className="flex gap-1">
                  <div className="btn-outline">Cancel</div>
                  <button className="btn">Send</button>
                </div>
              </div>
            </form>
          </Modal>
        </div>
        <Collapsible accordion={false}>
          <CollapsibleItem
            expanded={false}
            header="How can I find metabins location, status, and working details?"
            node="div"
          >
            The Citizen Dashboard will provide users with information about each
            metabin's location, activity status, and waste collected.
          </CollapsibleItem>
          <CollapsibleItem
            expanded={false}
            header=" How can I determine if a bin is filled with trash?"
            node="div"
          >
            Smart sensing IoT devices have been integrated into each Metabin to
            monitor. It will automically send the raise pickup whenever the bins
            get filled with waste.
          </CollapsibleItem>
          <CollapsibleItem
            expanded={false}
            header="What can I do, if the bin is inactive?"
            node="div"
          >
            You can send us a message on Map tab where each bin has message icon
            on the left panel. Also contact us at tech@cercle.com.
          </CollapsibleItem>

          <CollapsibleItem
            expanded={false}
            header="What happens to the waste collected from the metabins?"
            node="div"
          >
            The waste collected from the metabins is processed at recycling
            hubs, where it undergoes multiple recycling operations and is
            reprocessed again.
          </CollapsibleItem>

          <CollapsibleItem
            expanded={false}
            header="What is the purpose of bin tab?"
            node="div"
          >
            List of metabins according to location with complete details of
            every metabin and you can also raise request for collection prior to
            the bin filled completely.
          </CollapsibleItem>

          <CollapsibleItem
            expanded={false}
            header="How do I know if the waste has been picked up from the metabin?"
            node="div"
          >
            As soon as the pickup is raised, collection partner will receive
            notification. They will allocate a person to collect waste from
            metabin from where they get pickup request. Once the pickup
            completed, you can able to view complete pickups history on
            dashboard.
          </CollapsibleItem>
        </Collapsible>
      </div>
    </div>
  );
}

export default FAQ;
