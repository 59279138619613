import React from "react";
import { Tabs, Tab, Modal, Button } from "react-materialize";
import Wallet from "./Wallet";
import TrackExpenses from "../components/payments/TrackExpenses";
import AddBalance from "../components/payments/AddBalance";
import BillingDetails from "../components/payments/BillingDetails";

function Payments() {
  return (
    <div >
      <span className="flex align-center space-between ">
        <b className="normal-size">Payments</b>

        <Modal
          id="Modal-10"
          open={false}
          trigger={<Button className="btn " disabled >Add Balance</Button>}
        >
          <AddBalance />
        </Modal>
      </span>

      <div className=" mt-1  p-1 metabin-card">
        <Tabs className="mt-1">
          <Tab title="wallet">
            <Wallet />
          </Tab>
          <Tab title="Track Expenses">
            <TrackExpenses />
          </Tab>
          <Tab title="Billing Details">
            <BillingDetails />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Payments;
