export const colorTheme = (state={primary:"#E61D2A",secondary:"#F19A9F"},action)=>{
    switch(action.type){
        case "GETING_COLOR_THEME":
            return { loading:true }
        case "GOT_COLOR_THEME":
            return {loading:false ,primary:"# ",secondary:"#e9d7f7"    }
        case "CANNOT_GET_COLOR_THEME":
            return { loading:false}
        default :
        return state
    }
}