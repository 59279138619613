import React, { useEffect } from "react";
import { Tab, Tabs, Modal, Button } from "react-materialize";
import AccountsTable from "../components/accounts/AccountsTable";
import InvitationsTable from "../components/accounts/InvitationsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  inivitAccountAction,
  getOrganizationAction,
} from "../redux/action/MetaBinAction";
import { useState } from "react";
import { ClipLoader } from "react-spinners";

function Accounts() {
  const [invitedEmail, setInvitedEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invitedEmail == "") {
      setErrorMessage(
        "Please Enter Email Address Of The Account You Want To Invite. "
      );
    } else {
      dispatch(
        inivitAccountAction(invitedEmail, () => {
          setInvitedEmail("");
          window.M.modal
            .getInstance(document.getElementById("invite-account"))
            .close();
        })
      );
    }
  };

  const { orgainzation, inviting, loading } = useSelector(
    (state) => state.organizationStore
  );
  useEffect(() => {
    dispatch(getOrganizationAction());
  }, [dispatch]);
  return (
    <div>
      <div className="flex align-center space-between mb-1 ">
        <b className="normal-size">Accounts Management</b>
        <Modal
          trigger={
            <Button
              className="btn-floating  waves-effect waves-light cercle-purple"
              tooltip="Invite Account"
            >
              <i className="material-symbols-outlined">person_add</i>
            </Button>
          }
          id="invite-account"
        >
          <div>
            <form>
              <b>Invite New Account:</b>
              <p className="grey-text">
                Enter The Email Address Of The Account You Want To Invite
              </p>
              <input
                type={"email"}
                placeholder="eg. johndoe@company.com"
                className=" p-1 browser-default input-text "
                value={invitedEmail}
                onChange={(e) => setInvitedEmail(e.target.value)}
                onFocus={() => setErrorMessage("")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit(e);
                }}
              />
              {errorMessage && (
                <p className="small-text red-text">{errorMessage}</p>
              )}
              <div className="mt-2 flex align-center gap-1 justify-end ">
                <Button
                  className="primary-btn cercle-purple-text bold white"
                  modal="close"
                >
                  Close
                </Button>

                <button
                  className="btn cercle-purple "
                  onClick={handleSubmit}
                  disabled={inviting}
                >
                  {inviting ? (
                    <ClipLoader color={"#fff"} loading={inviting} size={15} />
                  ) : (
                    "Send Invitation"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div className="metabin-card p-1 ">
        <Tabs>
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false,
            }}
            title="Accounts"
            idx="TabID1fade"
          >
            <AccountsTable accounts={orgainzation.accounts} />
          </Tab>
          <Tab
            active
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false,
            }}
            title="Invitations"
          >
            <InvitationsTable
              invitations={orgainzation.invitations}
              loading={loading}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Accounts;
