import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-materialize";
import { showFillLevelText, showFillLevelValue } from "../../helpers/helper";

function TopBinsCard({ topMetaBins }) {
  const navigate = useNavigate();
  if (!topMetaBins) {
    return;
  }
  return (
    <Card style={{ height: "100%" }} className="metabin-card ">
      <div className="flex space-between mb-1 align-center  ">
        <b> Top Bins </b>
        <div className="btn-secondary flex align-center justify-center center">
          <p className="cercle-purple-text ">View All</p>
        </div>
      </div>

      {topMetaBins.map((item, index) => {
        if (index < 6)
          return (
            <div style={{ borderBottom: "1px solid #efefef" }}>
              <div
                className="flex align-end space-between  hover "
                key={index}
                onClick={() => navigate(`/bin-details/${item._id}`)}
              >
                <div className="flex align-center " style={{ width: "70%" }}>
                  <img
                    src="../images/smart-bin.png"
                    style={{ width: "50px", height: "50px" }}
                    alt="smart-bin"
                  />
                  &nbsp;
                  <div style={{ width: "100%" }}>
                    <div className=" space-between flex align-center ">
                      <b className="bold cercle-purple-text ">
                        {item.location.city}
                      </b>
                      <b className=" grey-text small-text ">
                        {showFillLevelText(item.fillLevel)}
                      </b>
                    </div>

                    <div className="full-width  progress-line">
                      <div
                        className="progress-bg-line"
                        style={{ height: "5px" }}
                      ></div>
                      <div
                        className="progress-bg-line2"
                        style={{
                          width: `${showFillLevelValue(item.fillLevel)}%`,
                          height: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <p className="cercle-purple-text small-text ">
                  {item.productCount} Products
                </p>
              </div>
            </div>
          );
      })}

      {/* <div className="flex space-between mb-1 align-center  ">
        <b> Top Bins </b>
        <div className="btn-secondary flex align-center justify-center center">
          <p className="cercle-purple-text ">View All</p>
        </div>
      </div>
      {topMetaBins?.map((item, index) => {
        if (index < 6)
          return (
            <div
              className="flex align-center space-between hover  mb-1"
              key={index}
              onClick={() => navigate(`/bin-details/${item._id}`)}
            >
              <div className="flex align-center ">
                <img
                  src="../images/smart-bin.png"
                  style={{ width: "50px", height: "50px" }}
                  alt="smart-bin-image"
                />
                &nbsp;
                <div>
                  <b className="bold cercle-purple-text ">{index}</b>
                  <p className="grey-text small-text">
                    {item.metabin[0]?.address?.split(" ").slice(0, 3).join(" ")}
                  </p>
                </div>
              </div>
              <p className="cercle-purple-text">{item.count} Bottles</p>
            </div>
          );
      })}*/}
    </Card>
  );
}

export default TopBinsCard;
