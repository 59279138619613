import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-materialize";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../../redux/action/auth";

function UserSettinge() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { profile } = useSelector((state) => state.profileStore);
  return (
    <div style={{ height: "100%" }} className="full-width">
      <div
        className="flex  full-width "
        style={{ backgroundColor: "#F4E7FF", padding: "10px" }}
      >
        <img
          src="../images/user_placeholder.jpg"
          className="circle"
          style={{ height: "40px", width: "40px" }}
          alt="profile"
        />
        <div className="flex  column ml-1 ">
          <p className="capitalize">{profile.name}</p>
          <p className="grey-text small-text">{profile.email}</p>
        </div>
      </div>

      <div
        className="flex  align-center p-1"
        onClick={() => navigation("/settings")}
      >
        <span className=" bif-icon material-symbols-outlined">person</span>
        <p>Account Setting</p>
      </div>

      <Modal
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
        id="Modal-10"
        open={false}
        options={{}}
        trigger={
          <div className="flex  align-center p-1 red-text " node="button  ">
            <span className="material-symbols-outlined primary ">logout</span>
            <p>Logout</p>
          </div>
        }
      >
        <b className="normal-size">Confirm Logout:</b>
        <p className="semi-bold">Are you sure you want to logout?</p>

        <div className="flex align-center justify-end ">
          <button
            className="btn-small"
            onClick={() => dispatch(logoutUserAction())}
          >
            Yes
          </button>
          ,
          <Button
            className="red btn-small white-text "
            flat
            modal="close"
            node="button"
            waves="green"
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default UserSettinge;
