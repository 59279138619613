import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { confirmOTP } from "../redux/action/auth";

function ConfirmOTP() {
  const isTablet = useMediaQuery({ query: "(max-width:850px)" });
  const { session } = useParams();
  const [openMenu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { phoneNumber } = location.state;
  const navigate = useNavigate();
  const { showLoading } = useSelector((state) => state.loading);
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (OTP === "" || OTP.length < 4) return setOTPError("No OTP Entered.");
    dispatch(confirmOTP(phoneNumber, OTP, session, navigate));
  };

  return (
    <div className="auth-page">
      {showLoading && <p>loading...</p>}
      {!isTablet ? (
        <header className="full-width ph-2 p-1 flex  space-between pt-1">
          <img src="../images/cercle-logo.png" style={{ height: "28px" }} />

          <div
            className="flex space-between align-center gap-1"
            style={{ width: "28vw" }}
          >
            <a href="https://cerclex.com/" className="black-text"  >
              <p className="normal-size">Home</p>
            </a>

            <a href="https://cerclex.com/about-us/" className="black-text"  >
              <p className="normal-size">About Us</p>
            </a>

            <a href="https://cerclex.com/blogs/"className="black-text"  >
              <p className="normal-size">Blog</p>
            </a>

            <a href="https://cerclex.com/#deep-dive" className="black-text"  >
              <p className="normal-size">Contact Us</p>
            </a>
          </div>
        </header>
      ) : (
        <div className=" flex space-between  align-center p-1 ">
          <img src="../images/logo.png" alt="logo" style={{ width: "100px" }} />
          <span
            className="material-symbols-outlined icon   "
            onClick={() => setMenu(true)}
          >
            menu
          </span>
        </div>
      )}

      <div
        className=" flex  "
        style={{ height: "80%" }}
      >
        <div
          className=" flex box  "
          style={{ height: ` ${!isTablet ? "90%" : ""}`, width: "55vw" }}
        >
          {!isTablet && (
            <div
              className="cercle-purple white-text p-2 "
              style={{ width: "50%", borderRadius: "20px 0 0 20px" }}
            >
              <b className="large-size flex column border-bottom">
                <span>Let’s make it </span>
                <span>happen together! </span>
              </b>
              <p className="mv-2">
                Thank you for choosing Cercle X, Please register with your
                mobile number.
              </p>
              <div className="mt-3 align-center flex center justify-center ">
                <img src="../images/earth-logo.png" className="earth-image" />
              </div>
            </div>
          )}

          <div
            className="flex column space-between  center "
            style={{
              width: `${isTablet ? "100%" : "50%"}`,
              borderRadius: "0 20px 20px 0"
            }}
          >
            <div style={{ padding: `${!isTablet ? " 0 20px 0 20px " : "0"}` }}>
              <form onSubmit={handleSubmit}>
                <b className="large-size flex column mb-2">
                  {!isTablet && <span className="purple-text">Welcome!</span>}
                  <span>SignIn</span>
                </b>

                {isTablet && (
                  <div className="mb-2">
                    <b className="large-size border-bottom ">
                      Let’s make it happen together!
                    </b>
                    <p>
                      Thank you for choosing Cercle X, Please register with your
                      mobile number.
                    </p>
                  </div>
                )}
                <div className="flex column align-start ">
                  <b>Enter Your OTP below</b>

                  <div className="input-field">
                    <span className="material-symbols-outlined">password</span>
                    <input
                      className="browser-default input-field "
                      placeholder="Enter OTP"
                      type="text"
                      onChange={(e) => {
                        setOTP(e.target.value);
                        setOTPError("");
                      }}
                      value={OTP}
                      maxLength="6"
                    />
                  </div>
                  {OTPError && (
                    <p className="small-text red-text">{OTPError}</p>
                  )}
                </div>

                <div className="submit-btn mv-2 ">
                  <button className="btn-submit full-width " type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOTP;
