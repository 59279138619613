import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const MaterialTypeCollection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { analytics } = useSelector((state) => state.analyticsStore);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="collection-report-chart p-1 box white">
      <div className="flex align-center space-between ">
        <b className="normal-size">
          Collection Percentage Based On Material Type
        </b>
        <b className="grey-text">Since Last Pickup</b>
      </div>

      <ResponsiveContainer width="100%" height={260}>
        <PieChart>
          <Pie
            data={analytics.productsCollectedMaterialTypePercentage || []}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            startAngle={-270}
            endAngle={-630}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {analytics.productsCollectedMaterialTypePercentage?.map(
              (entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              )
            )}
          </Pie>
          <Tooltip
            formatter={(value, name, props) =>
              `${value}% (${props.payload.total})`
            }
          />
          <Legend formatter={(value) => `${value}`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MaterialTypeCollection;
