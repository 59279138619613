import React from "react";
import { useMediaQuery } from "react-responsive";

function SaveWater() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  return (
    <div>
      <div
        className={
          isTablet
            ? "flex align-center  gap-2 column "
            : "flex align-center  gap-2"
        }
      >
        <img
          src="../images/save-water.png"
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
          loading="lazy"
          alt="save-water"
        />

        <div className=" full-width  ">
          <b className="cercle-purple-text normal-size ">
            Saving Water through Plastic Recycling
          </b>
          <span className="flex align-center mv-2 ">
            <h2 className="bold cercle-purple-text">00.00</h2>
            <b>&nbsp;gal</b>
          </span>
          <p>
            By recycling one ton of PET plastic, we can save approximately 0.00
            gallons of water, which is the amount of water needed to produce new
            PET plastic from petroleum.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SaveWater;
