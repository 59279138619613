import React, { useState } from "react";
import Header from "../components/frame/Header";
import Aside from "../components/frame/Aside";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getOrganizationAction } from "../redux/action/MetaBinAction";

const MemoHeader = React.memo(Header);
const MemoAside = React.memo(Aside);

function Frame({ children }) {
  const [openAside, setOpenAside] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationAction());
  }, [dispatch]);

  return (
    <div className="frame">
      <MemoHeader setOpenAside={setOpenAside} openAside={openAside} />
      <div className="overlay">
        <MemoAside openAside={openAside} setOpenAside={setOpenAside} />
        <section className={openAside ? "section opne-asid" : "section"}>
          {children}
        </section>
      </div>
    </div>
  );
}

export default Frame;
