import React from "react";
import { useMediaQuery } from "react-responsive";

function TreeSaved() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  return (
    <div>
      <div
        className={
          isTablet
            ? "flex align-center  gap-2 column "
            : "flex align-center  gap-2 "
        }
      >
        <img
          src="../images/trees-saving.png"
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
          loading="lazy"
          alt="trees-save"
        />

        <div className=" full-width  ">
          <b className="cercle-purple-text normal-size ">
            Preserving Natural Resources through Recycling
          </b>
          <span className="flex align-center mv-2 ">
            <h2 className="bold cercle-purple-text">00</h2>
            <b>&nbsp;</b>
          </span>
          <p>
            Through our recycling program, we have saved 0.00 metric tons of CO2
            emissions in the last year. This is equivalent to the emissions from
            0.00 cars driven for a year.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TreeSaved;
