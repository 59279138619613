import React, { useState, useEffect, useRef } from "react";
import data from "../helpers/dashboard.json";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function LiveSearch() {
  const ref = useRef();
  const navigate = useNavigate();

  const [wordEntered, setWordEntered] = useState("");
  const [filterResult, setFilterResult] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [itemSelected, setItemSelected] = useState("");

  const handleKeyDown = (event) => {
    setItemSelected(filterResult[selectedIndex]);

    if (event.keyCode === 38) {
      setSelectedIndex((prevIndex) =>
        prevIndex - 1 < 0 ? filterResult.length - 1 : prevIndex - 1
      );
    } else if (event.keyCode === 40) {
      setSelectedIndex((prevIndex) =>
        prevIndex + 1 >= filterResult.length ? 0 : prevIndex + 1
      );
    }
  };

  function filterData(searchString) {
    return data.filter((item) => {
      const names = item.wordList;
      const searchTerm = searchString;

      return names.some((word) => word.includes(searchTerm));
    });
  }

  function filterItems(searchString) {
    const filteredItems = data.filter((item) => {
      const names = item.wordList.map((name) => name.toLowerCase());
      const searchTerm = searchString.toLowerCase();
      return names.some((name) => name.includes(searchTerm));
    });
    return filteredItems;
  }

  const openPage = (event) => {
    if (event.key === "Enter") {
      navigate(itemSelected.link);
      setOpenSearch(false);
      setWordEntered("");
    }
  };

  useEffect(() => {
    const filteredItems = filterItems(wordEntered);
    setFilterResult(filteredItems);
  }, [wordEntered]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSearch(false);
      setWordEntered("");
    }
  };

  return (
    <div>
      <div className=" search-tab ">
        <div
          className="flex column  justify-center  align-center "
          style={{ height: "2.6rem", width: "2.6rem" }}
        >
          <span className="material-symbols-outlined grey-text ">search</span>
        </div>
        <input
          className="browser-default input-text"
          placeholder="Search In Dashboar..."
          onChange={(e) => {
            filterData(e.target.value);
            setWordEntered(e.target.value);
            setOpenSearch(true);
            setSelectedIndex(-1);
          }}
          value={wordEntered}
          type="text"
          onKeyDown={handleKeyDown}
          onKeyPress={openPage}
        />
      </div>

      {filterResult.length === 0 && wordEntered !== "" && openSearch && (
        <div className=" search-result  " ref={ref}>
          <div className="flex align-center justify-center ">
            <p className="mv-1 grey-text" style={{ fontSize: "10px" }}>
              No Results for {wordEntered}.
            </p>
          </div>
        </div>
      )}

      {filterResult.length > 0 && wordEntered !== "" && openSearch && (
        <div className=" search-result  " ref={ref}>
          {filterResult.length === 0 && wordEntered !== "" && (
            <div className="flex align-center justify-center ">
              <p className="mv-1 grey-text" style={{ fontSize: "10px" }}>
                No Results
              </p>
            </div>
          )}
          {filterResult.map((item, index) => {
            return (
              <Link
                to={item.link}
                onClick={() => {
                  setOpenSearch(false);
                  setWordEntered("");
                }}
                className="red"
                key={index}
              >
                <div
                  key={index}
                  className={
                    index === selectedIndex
                      ? "selected search-item search-item-active "
                      : "search-item "
                  }
                  tabIndex="0"
                  onMouseEnter={() => setSelectedIndex(-1)}
                >
                  <span
                    className="material-symbols-outlined cercle-purple-text "
                    style={{ fontSize: "22px" }}
                  >
                    {item.icon}
                  </span>
                  <span>
                    <b
                      style={{ fontSize: "14px", margin: "0", color: "#000 " }}
                    >
                      {item.title}
                    </b>
                    <p
                      className="grey-text "
                      style={{ fontSize: "11px", margin: "0" }}
                    >
                      {item.descraption}
                    </p>
                  </span>
                </div>
              </Link>
            );
          })}
          <div className="flex align-center justify-center ">
            <p className="mv-1 grey-text" style={{ fontSize: "10px" }}>
              End of Results - There are no more items to display
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveSearch;
