import React from "react";
import { useMediaQuery } from "react-responsive";

function AuthHeader() {
  const isTablet = useMediaQuery({ query: "(max-width:790px)" });

  return (
    <div className="flex space-between full-width auth-header ">
      {!isTablet ? (
        <div className="full-width   flex align-center  space-between p-1">
          <img
            src="../images/cercle-logo.png"
            style={{ height: "18px", width: "80px" }}
            className="hover"
            alt="logo"
          />

          <div className="flex space-between align-center gap-2 ">
            <a href="https://cerclex.com/" className="black-text">
              <b>Home</b>
            </a>

            <a href="https://cerclex.com/about-us/" className="black-text">
              <b>About Us</b>
            </a>

            <a href="https://cerclex.com/blogs/" className="black-text">
              <b>Blog</b>
            </a>

            <a href="https://cerclex.com/#deep-dive" className="black-text">
              <b>Contact Us</b>
            </a>
            <button
              className="btn"
              onClick={() =>
                (window.location.href = "https://accounts.thinktrash.co/")
              }
            >
              Register
            </button>
          </div>
        </div>
      ) : (
        <div className=" flex space-between  align-center p-1 ">
          <img
            src="../images/cercle-logo.png"
            alt="logo"
            style={{ width: "100px" }}
          />
          <span className="material-symbols-outlined icon ">menu</span>
        </div>
      )}
    </div>
  );
}

export default AuthHeader;
