import React from "react";
import { useMediaQuery } from "react-responsive";

function DownloadApp() {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <div
      className={
        isMobile
          ? "column metabin-card p-1 "
          : "flex align-center metabin-card  p-1 space-between "
      }
      style={{ position: "relative" }}
    >
      <div
        className={isMobile ? "flex align-start column  " : "flex align-start "}
      >
        <div className="flex align-center justify-center ">
          {!isMobile && (
            <div className="flex column ">
              <img
                src="../images/qrcodeMetaBin.png"
                alt="download-app"
                className="download-app"
                style={{
                  width: "170px",
                  height: "170px",
                  objectFit: "contain"
                }}
              />
             
            </div>
          )}
          {isMobile && <p className="normal-size">Explore Metabins</p>}
        </div>

        <div className=" column ml-2 ">
          {!isMobile && <h5>Explore Metabins</h5>}
          {!isMobile && (
            <div className="small-text">
              {" "}
              <p className="grey-text mt-1">
                To access Metabins, simply scan the QR code below with your
                smartphone camera. Metabins is designed as a Progressive Web
                App,
              </p>
              <p className="grey-text  ">
                offering seamless access without the need for downloads. Whether
                you're an admin managing multiple bins or a user keen on making
                a difference,
              </p>
              <p className="grey-text ">
                Metabins simplifies staying informed and taking action. Scan the
                code now and start making a positive impact on your community.
              </p>
            </div>
          )}
        </div>
        {isMobile && (
          <div className="flex align-center gap-1  ">
            <div className="mt-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsbuyer"
                className="primary-btn flex  jagger-color  "
              >
                <img
                  src="../images/appleLogo.png"
                  style={{
                    width: "25px",
                    height: "25px",
                    objectFit: "contain"
                  }}
                  loading="lazy"
                  alt="apple-logo"
                />
                &nbsp;
                <div className="flex  column">
                  <p className="small-text">GET IT ON</p>
                  <p>App Store</p>
                </div>
              </a>
            </div>
            <div className="mt-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsbuyer"
                className="primary-btn flex  jagger-color "
              >
                <img src="../images/android.png" style={{ width: "25px" }} />
                &nbsp;
                <div>
                  <p className="small-text">GET IT ON</p>
                  <p>Google Play</p>
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <img
          src="../images/planetWarriorApp.png"
          style={{
            width: "250px",
            height: "190px",
            position: "absolute",
            objectFit: "",
            right: 0,
            bottom: 0,
            borderRadius: " 0 0  15px 0 "
          }}
          loading="lazy"
          alt="plant-warrior"
        />
      )}
    </div>
  );
}

export default DownloadApp;
