import React from "react";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 610px)" });
  return (
    <div className="grey lighten-4 p-1">
      <div className="full-width  container pv-4">
        <div
          className={
            isSmallScreen
              ? "flex column align-center "
              : "flex space-between gap-2  "
          }
        >
          <div style={{ width: isSmallScreen ? "100%" : "30vw" }}>
            <img src="../images/cercle-logo.png" style={{ width: "95px" }} />
            <p className="grey-text ">
              Cercle X strives to make the world a better place to live in. Our
              mission is to create a world without waste. Not just for us, but
              for our children and grandchildren. We want to build a cleaner,
              greener world – one that’s safe for today and tomorrow.
            </p>
          </div>

          <div style={{ width: isSmallScreen ? "100%" : "35vw" }} className={ isSmallScreen && "mt-2" } >
            <b>Contact Info</b>

            <span className="flex align-center gap-1 ">
              <span className="material-symbols-outlined purple-text ">
                call
              </span>
              <p className="grey-text">+91 9640496454</p>
            </span>

            <span className="flex align-center gap-1 mv-1">
              <span className="material-symbols-outlined purple-text ">
                mail
              </span>
              <p className="grey-text  ">sales@cerclex.com</p>
            </span>

            <span className="flex align-center gap-1 ">
              <span className="material-symbols-outlined purple-text ">
                location_on
              </span>
              <p className="grey-text  ">
                AIC Raise, Eachanari, Tamil Nadu 641021
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="flex align-center justify-center p-1 full-width white">
        <b className="small-text purple-text ">
          ©2023 Cercle X. All Rights Reserved
        </b>
      </div>
    </div>
  );
}

export default Footer;
