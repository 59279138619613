import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Button, Icon } from "react-materialize";
import UserSettinge from "./UserSettinge";
import Notifications from "./Notifications";
import { useMediaQuery } from "react-responsive";
import LiveSearch from "../../layout/LiveSearch";

function Header({ openAside, setOpenAside }) {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });
  const { orgainzation, loading } = useSelector(
    (state) => state.organizationStore
  );
  return (
    <header className="flex align-center space-between white full-width  header ph-1">
      <div className="flex align-center  ">
        <div
          className={openAside ? "open-menu-icon" : "menu-icon  "}
          onClick={() => setOpenAside(!openAside)}
        >
          {openAside ? (
            <span className="material-symbols-outlined menu-icon icon hover">
              close
            </span>
          ) : (
            <span className="material-symbols-outlined menu-icon icon hover ">
              segment
            </span>
          )}
        </div>

        <div className="company-profile flex mh-1  align-center ">
          <div className="flex column ml-1 justify-start align-start ">
            <b className="capitalize  truncate">{orgainzation.name}</b>
            <p className="small-text  ">MetaBin Dashboard</p>
          </div>
        </div>
      </div>

      {!isTablet && <LiveSearch />}

      <div className="flex align-end gap-1" style={{ paddingRight: "15px" }}>
        {!isTablet && (
          <Dropdown
            id="Dropdown_8"
            options={{
              alignment: "right"
            }}
            trigger={
              <div className="header-icon">
                <span className="material-symbols-outlined big-icon ">
                  notifications
                </span>
              </div>
            }
            className="drop-down-header-notifications"
          >
            <Notifications />
          </Dropdown>
        )}

        {!isMobile && (
          <Dropdown
            id="Dropdown_9"
            options={{
              alignment: "right"
            }}
            trigger={
              <div className="header-icon">
                <span className=" big-icon material-symbols-outlined">
                  person
                </span>
              </div>
            }
            className="drop-down-header"
          >
            <UserSettinge />
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default Header;
