import React, { useEffect } from "react";
import { Tabs, Tab } from "react-materialize";
import ItemsScans from "./maps/singleBin/ItemsScans";
import { useDispatch, useSelector } from "react-redux";
import { getsingleMetaBinAction } from "../redux/action/MetaBinAction";
import Loading from "react-loading";

function SingleBin({ openBinDetails }) {
  const dispatch = useDispatch();
  const { singleBin, loading } = useSelector((state) => state.singleMetaBin);

  useEffect(() => {
    if (openBinDetails.binId) {
      dispatch(getsingleMetaBinAction(openBinDetails.binId));
    }
  }, [dispatch, openBinDetails.binId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="white mb-2  ">
      <div className=" white  p-1 border">
        <div className="full-width  flex mb-1"></div>
        <div className="flex space-between align-center border-bottom  pb-1 ">
          <div className="flex">
            <img
              src="../images/smart-bin.png"
              style={{ width: "70px", height: "70px" }}
              alt="smart-bin"
            />
            <div className="ml-1">
              <span className="flex align-center ">
                <i
                  className="fas fa-map-pin   cercle-purple-text"
                  style={{ fontSize: 20, marginRight: 5 }}
                ></i>
                <b>
                  {singleBin.location?.district
                    ? singleBin.location.district
                    : singleBin.location?.city
                    ? singleBin.location.city
                    : "Unknown"}
                  , {singleBin.location?.state}, {singleBin.location?.country}
                </b>
              </span>
              <p className="grey-text small-text ">
                {singleBin.detailedAddress}
              </p>
              <p className="  grey-text capitalize ">
                {singleBin.material} bin
              </p>
              <div className="flex align-center">
                <div className="live-bin" style={{ marginRight: 5 }} />
                <p
                  className="  capitalize"
                  style={{
                    color: singleBin.status === "inactive" ? "red" : "green"
                  }}
                >
                  {singleBin.status}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleBin;
