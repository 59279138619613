import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMetaBinSessionAction } from "../../../redux/action/MetaBinAction";
import { formatDate } from "../../help/helper";

function ItemsScans({ binId }) {
  const dispatch = useDispatch();
  const { metaBinSessions, loading } = useSelector(
    (state) => state.singelBinSessions
  );

  useEffect(() => {
    dispatch(getSingleMetaBinSessionAction(binId));
  }, [dispatch]);
  return (
    <div className="mb-3">
      {metaBinSessions.map((item, index) => {
        return (
          <div className=" space-between align-center mt-1  " key={index}>
            <div className="flex align-center ">
              <img
                src="../images/QRCode.png"
                style={{ width: "30px", height: "30px" }}
                alt="bin"
              />
              &nbsp;
              <p>{item.productCount} Products Dropped</p>
            </div>

            <div className="flex align-center ">
              <p className="grey-text mr-1" style={{ fontSize: 12 }}>
                Started At:
              </p>
              <p className="black-text mr-1" style={{ fontSize: 12 }}>
                {new Date(item.startedAt).toLocaleTimeString()}
              </p>
            </div>

            <div className="flex align-center ">
              <p className="grey-text mr-1" style={{ fontSize: 12 }}>
                Ended At:
              </p>
              <p className="black-text mr-1" style={{ fontSize: 12 }}>
                {new Date(item.endedAt).toLocaleTimeString()}
              </p>
            </div>
            <div className="flex align-center ">
              <p className="grey-text mr-1" style={{ fontSize: 12 }}>
                Coins Earned:
              </p>
              <p className="black-text mr-1" style={{ fontSize: 12 }}>
                {item.productCount * 3} Coins
              </p>
            </div>

            <p className="grey-text small-text ">
              {new Date(item.createdAt).toDateString()}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default ItemsScans;
