import React from "react";
import { useSelector } from "react-redux";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip
} from "recharts";


function UserEngagement() {
  const { userEngagement } = useSelector((state) => state.userEngagement);
  const style = {
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px"
  };

  return (
    <div className="metabin-card p-1 mv-1 flex column align-center ">
      <div className="flex align-center space-between mb-2 full-width ">
        <b>User Engagement</b>

        {userEngagement && (
          <p>
            For{" "}
            <b style={{ fontSize: 18 }} className="cercle-purple-text">
              {userEngagement.uniqueUsers}
            </b>{" "}
            users and{" "}
            <b style={{ fontSize: 18 }} className="cercle-purple-text">
              {userEngagement.sessionsCount}
            </b>{" "}
            sessions so far
          </p>
        )}
      </div>

      <div style={{ width: "100%", height: "440px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="50%"
            cy="50%"
            innerRadius="20%"
            outerRadius="80%"
            barSize={22}
            data={userEngagement.data ? userEngagement.data : []}
            barGap={15}
          >
            <RadialBar
              // minAngle={205}
              label={{ position: "insideStart", fill: "#fff" }}
              background
              clockWise
              style={{ width: "40px" }}
              dataKey="count"
              strokeWidth={1}
              cornerRadius={30}
              startAngle={0}
            />
            <Tooltip />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
      <p className="grey-text small-text ">
        Track User Engagement and Identify Opportunities for Improvement.
      </p>
    </div>
  );
}

export default UserEngagement;
