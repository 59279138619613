import React from "react";
import Loading from "../../pages/Loading";

function InvitationsTable({ invitations, loading }) {
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <table className="striped  ">
        <thead>
          <tr>
            <th className="primary-text">#</th>
            <th className="primary-text">Email</th>
            <th className="primary-text">Phone Number</th>
            <th className="primary-text">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr></tr>
        </tbody>
      </table>

      {invitations.length === 0 ? (
        <div className="center">
          <h5 className="primary-text">No Invitations</h5>
        </div>
      ) : (
        <div>
          {invitations.map((invitation, index) => (
            <div key={index}>
              <table className="striped">
                <tbody>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{invitation.email}</td>
                    <td>{invitation.phoneNumber}</td>
                    <td>{invitation.status}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default InvitationsTable;
