import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
import { endOfMonth, startOfMonth } from "date-fns";
import { useDispatch } from "react-redux";
import { getNewUserInteractionData } from "../../redux/action/MetaBinAction";
import { useEffect } from "react";

function PaymentHistoryChart() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    dispatch(
      getNewUserInteractionData(startDate, endDate, setLoading, setData)
    );
  }, [dispatch, endDate, startDate]);

  return (
    <div className="p-1 metabin-card mt-2 ">
      <div style={{ height: "40vh", width: "100%" }}>
        <div className="flex space-between align-center pl-1 pr-1">
          <div className="flex align-center">
         
            <span className="ml-1 grey-text">
              ({startDate?.toDateString()} - {endDate?.toDateString()})
            </span>
          </div>

          <div className="chart-date-select  flex align-center gap-1 ">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className="browser-default input-text "
              dateFormat="dd/MM/yyyy"
            />
            <span className="material-symbols-outlined cercle-purple-text ">calendar_month</span>
          </div>
        </div>

        <ResponsiveContainer width="100%" height="85%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="1" x2="0">
                <stop offset="20%" stopColor="#ea8bfc" stopOpacity={0.91} />
                <stop offset="80%" stopColor="#fc5da2" stopOpacity={1} />
              </linearGradient>
            </defs>

            <XAxis dataKey="date" />
            <YAxis dataKey="count" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="count"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default PaymentHistoryChart;
