import { toast } from "react-toastify";

export const formatDate = (date) => {
  const createdAt = new Date(date);
  const formattedDate = createdAt.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return formattedDate;
};

export const notifyError = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
  });
};

export const showFillLevelText = (fillLevel) => {
  if (fillLevel < 25) {
    return "Empty";
  } else if (fillLevel >= 25 && fillLevel < 50) {
    return "Less Than Half Filled";
  } else if (fillLevel >= 50 && fillLevel < 75) {
    return "Half Filled";
  } else if (fillLevel >= 75 && fillLevel < 90) {
    return "More Than Half Filled";
  } else if (fillLevel > 90) {
    return "Full";
  }
};

export const showFillLevelValue = (fillLevel) => {
  if (fillLevel < 25) {
    return 0;
  } else if (fillLevel >= 25 && fillLevel < 50) {
    return 25;
  } else if (fillLevel >= 50 && fillLevel < 75) {
    return 50;
  } else if (fillLevel >= 75 && fillLevel < 90) {
    return 75;
  } else if (fillLevel > 90) {
    return 100;
  }
}
