import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import SingleWeekCharts from "../Charts/SingleWeekCharts";

function SingleStateMap({ stateSelected }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [cities, setCities] = useState([]);
  const [citySelected, setCitySelected] = useState([]);
  const [numberOfBinsInState, setNumberOfBinsInState] = useState(0);

  function collectBinsInState() {
    stateSelected?.cities.map((city) => {
      setNumberOfBinsInState(numberOfBinsInState + city.metabins?.length);
    });
  }

  const filterCities = (cityName) => {
    const bins = stateSelected?.cities?.find((item) => item.name === cityName);
    setCitySelected(bins);
  };

  useEffect(() => {
    setNumberOfBinsInState(0);
    collectBinsInState();
    if (stateSelected) {
      setCities(stateSelected?.cities);
      setCitySelected(stateSelected?.cities[0]);
    }
  }, [stateSelected]);

  if (!stateSelected) {
    return (
      <div className="p-1" style={{ height: !isTabletOrMobile && "84vh" }}>
        <b className="normal-size ">State Overview</b>
        <div className="flex align-center justify-center mt-5 column">
          <span className="material-symbols-outlined large grey-text ">
            analytics
          </span>
          <p className="grey-text ">No state selected.</p>
        </div>
      </div>
    );
  }

  if (citySelected?.metabins?.length === 0 || !citySelected) {
    return (
      <div className=" p-1  " style={{ height: !isTabletOrMobile && "84vh" }}>
        <b className="normal-size ">State Overview</b>

        <div className="flex align-center justify-center mt-5 column">
          <span className="material-symbols-outlined large grey-text ">
            analytics
          </span>
          <p className="grey-text ">
            No bins found in {stateSelected?.stateName}.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className=" full-height  p-1 ">
      <div className="mb-1">
        <b className="normal-size">State Overview</b>
        <span> (City Based Sessions Per Day)</span>
      </div>

      <div className="flex space-between mb-2 align-center ">
        <select
          name="cars"
          id="cars"
          className="browser-default select"
          value={citySelected}
          style={{ width: "250px"  }}
        >
          {cities?.map((item, index) => {
            return (
              <option key={index} onClick={() => filterCities(item.name)}>
                {item.name}
              </option>
            );
          })}
        </select>
        <b>
          <span className="grey-text">{numberOfBinsInState} Bins &nbsp;</span>in{" "}
          {stateSelected?.stateName}
        </b>
      </div>
      <div className="full-width flex align-center justify-center ">
        <SingleWeekCharts chartData={citySelected?.chartData?.reverse()} />
      </div>

      {citySelected?.metabins?.map((item, index) => {
        if (index <= 5)
          return (
            <div className="flex align-center space-between mt-1" key={index}>
              <div className="flex align-center  " style={{ width: "75%" }}>
                <span
                  className=" cercle-purple-text material-symbols-outlined"
                  style={styles.icon_style}
                >
                  delete
                </span>
                &nbsp;
                <div>
                  <div className="flex align-center gap-1 ">
                    {item.status === "active" ? (
                      <p className="active-bin capitalize">&nbsp;Active</p>
                    ) : (
                      <p className="unactive-bin capitalize ">
                        &nbsp;{item.status}
                      </p>
                    )}{" "}
                  </div>
                  <p className="grey-text small-text  " numberOfLines={1}>
                    {item.address.split(" ").slice(0, 2).join(" ")}...
                  </p>
                </div>
              </div>
              <p className="cercle-cercle-purple-text ml-1 ">
                {item.fillLevel}%
              </p>
            </div>
          );
      })}

      {citySelected?.metabins?.length > 4 && (
        <div className="btn-secondary flex align-center justify-center center mt-2 ">
          <p className="cercle-cercle-purple-text hover purple-text btn-outline full-width mt-2 "  >View All</p>
        </div>
      )}
    </div>
  );
}

const styles = {
  icon_style: {
    backgroundColor: "#FBF1FF",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

export default SingleStateMap;
