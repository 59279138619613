import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div className="flex column justify-center align-center  ">
      <img src="../images/404.jpg" style={{ width: "20vw" }} />
      <p className="grey-text normal-size ">Oops, Page Not Found</p>
      <button className="btn-large mt-2 " onClick={() => navigate(-1)}>
        Go Back
      </button>
    </div>
  );
}

export default ErrorPage;
