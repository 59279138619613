import React from "react";

function PickupHistoryTable() {
  return (
    <div style={{ height: "100%" }} className="white full-width p-1 box ">
      <div className="flex align-center space-between ">
        <b>Pickups History</b>
        <div role="button" className="btn-outline">
          View All
        </div>
      </div>
      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">No.</th>
            <th className="primary-text">Date</th>
            <th className="primary-text">Partner</th>
            <th className="primary-text">Fill Level</th>
            <th className="primary-text ">Status</th>

            <th className="primary-text flex align-center justify-center ">
              Action
            </th>
          </tr>
        </thead>

        <tbody></tbody>
      </table>
      <div className="flex full-width column p-1 align-center justify-center mt-2 mb-2">
        <span className="material-symbols-outlined large grey-text">
          local_shipping
        </span>
        <p className="grey-text small-text">
          Currently you don’t have any pickups.
        </p>
      </div>
    </div>
  );
}

export default PickupHistoryTable;
