import React from "react";
import { Card } from "react-materialize";

function MainTable() {
  return (
    <Card className="full-width metabin-card  " style={{ height: "100%" }}>
      <div className="flex space-between  align-center  ">
        <b> Current Pickups </b>
        <div className="btn-secondary flex align-center justify-center center">
          <p className="cercle-purple-text ">View All</p>
        </div>
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">NO.</th>
            <th className="primary-text">Date</th>
            <th className="primary-text">Metabin ID</th>
            <th className="primary-text">Location</th>
            <th className="primary-text flex align-center justify-center">
              Status
            </th>
          </tr>
        </thead>

        <tbody></tbody>
      </table>
      <div className="flex full-width column align-center justify-center mt-4">
        <span className="material-symbols-outlined large grey-text ">local_shipping</span>
        <p className="grey-text small-text">
          Currently you don’t have any pickups.
        </p>
      </div>
    </Card>
  );
}

export default MainTable;
