import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
} from "recharts";

function SingleWeekCharts({ chartData }) {


  chartData = chartData ? chartData : [];

  return (
    <div className="box ">
      <BarChart width={380} height={300} data={chartData}>
        <XAxis dataKey="date" stroke="#8884d8" />
        <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />

        <Bar
          dataKey="count"
          fill="#ebe0f4"
          stroke="#6f2da8"
          strokeWidth={1}
          barSize={30}
          radius={[5, 5, 0, 0]}
        />
      </BarChart>
    </div>
  );
}

export default SingleWeekCharts;
