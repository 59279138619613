import { async } from "q";
import { metaBin, metaBinManagement } from "../config/configURL";
import { toast } from "react-toastify";
import Toastify from "../../layout/Toastify";
import { notifyError } from "../../helpers/helper";

export const createMetaBinAction =
  (metaBinDetails, callBack) => async (dispatch) => {
    toast(
      <Toastify
        notification={{
          body: "descrip",
          title: "Bin Name",
        }}
      />,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      }
    );
  };

export const getAllMetaBinsAction = () => async (dispatch) => {
  dispatch({ type: "GET_ALL_METABIN" });
  metaBinManagement
    .get("/metabins/read")
    .then((res) => {
      dispatch({ type: "GOT_ALL_METABIN", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ALL_METABIN" });
    });
};

export const getsingleMetaBinAction = (id) => async (dispatch) => {
  dispatch({ type: "GET_SINGLE_METABIN" });
  metaBinManagement
    .get(`/metabins/read/admin/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLEMETABIN", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SINGLEMETABIN" });
    });
};

export const getProfileAction = () => async (dispatch) => {
  dispatch({ type: "GET_PROFILE" });
  metaBinManagement
    .get("/profile/read/my-profile")
    .then((res) => {
      dispatch({ type: "GOT_PROFILE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PROFILE" });
    });
};

export const getNotificationsAction = () => async (dispatch) => {
  dispatch({ type: "GET_NOTIFICATIONS" });
  metaBinManagement
    .get("/profile/read/notifications")
    .then((res) => {
      dispatch({ type: "GOT_NOTIFICATIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_NOTIFICATIONS" });
    });
};

export const getAnalyticsAction = () => async (dispatch) => {
  dispatch({ type: "GETING_ANALYTICS" });
  metaBinManagement
    .get("/analytics/read/")
    .then((res) => {
      dispatch({ type: "GOT_ANALYTICS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ANALYTICS" });
    });
};

export const getAnalyticsOverviewAction = () => async (dispatch) => {
  dispatch({ type: "GETING_ANALYTICS" });
  metaBinManagement
    .get("/analytics/read/analytics-overview")
    .then((res) => {
      dispatch({ type: "GOT_ANALYTICS_OVERVIEW", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ANALYTICS" });
    });
};

export const getSingleMetaBinSessionAction = (id) => async (dispatch) => {
  dispatch({ type: "GETING_SINGLE_BIN_SESSIONS" });
  metaBinManagement
    .get(`/session/read/all/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_BIN_SESSIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SINGLE_BIN_SESSIONS" });
    });
};

export const getProductsCollectedByDateAction =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_PRODUCTS_BY_DATE" });
    metaBinManagement
      .get(
        `/analytics/read/products-collected-by-date?start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        dispatch({ type: "GOT_PRODUCTS_BY_DATE", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_PRODUCTS_BY_DATE" });
      });
  };

export const getCollectionReportData =
  (startDate, endDate, setLoading, setData) => async (dispatch) => {
    setLoading(true);
    metaBinManagement
      .get(
        `/analytics/read/collection-report?start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

export const getNewUserInteractionData =
  (startDate, endDate, setLoading, setData) => async (dispatch) => {
    setLoading(true);
    metaBinManagement
      .get(
        `/analytics/read/new-user-interactions?start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

export const getProductsSessionAction =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_PRODUCTS_SESSION" });
    metaBinManagement
      .get(
        `/analytics/read/grouped-sessions-by-date?start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        dispatch({ type: "GOT_PRODUCTS_SESSION", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_PRODUCTS_SESSION" });
      });
  };

export const getUserEngagementData =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_USER_ENGAGEMENT_DATA" });
    metaBinManagement
      .get(`/analytics/read/user-engagement?`)
      .then((res) => {
        dispatch({ type: "GOT_USER_ENGAGEMENT_DATA", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_USER_ENGAGEMENT_DATA" });
      });
  };

export const readMetaBinSessionsByDate =
  (binId, start, end) => async (dispatch) => {
    dispatch({ type: "GETTING_SINGLE_BIN_SESSION" });
    metaBinManagement
      .get(
        `metabins/read/grouped-sessions-by-date/${binId}?start=${start}&end=${end}`
      )
      .then((res) => {
        dispatch({ type: "GOT_METABIN_SESSION", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_SESSIONS" });
      });
  };

export const getSessionBefor60Days =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTIN_GSESSION" });
    metaBinManagement
      .get(
        `/analytics/read/grouped-sessions-by-date?start=${startDate}&end=${endDate}`
      )
      .then((res) => {})
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_SESSIONS" });
      });
  };

export const getSessionAnalyticsByDate =
  (start, end, setData, todayDate) => async (dispatch) => {
    dispatch({ type: "GETTING_SESSIONS" });
    metaBinManagement
      .get(`/analytics/read/grouped-sessions-by-date?start=${start}&end=${end}`)
      .then((res) => {
        setData(res.data);

        dispatch({
          type: "GOT_SESSION_FOR_LAST2_MONTH",
          payloadForLast2Month: res.data?.length,
        });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_SESSION" });
      });
  };

export const getProductsCollectedByDate =
  (start, end, setData, setLoading) => async (dispatch) => {
    setLoading(true);
    metaBinManagement
      .get(
        `/analytics/read/products-collected-by-date?start=${start}&end=${end}`
      )
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_PRODUCTS_COLLECTED" });
        setLoading(false);
      });
  };

export const getMapDataAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_MAP_DATA" });
  metaBinManagement
    .get("/analytics/read/map-data")
    .then((res) => {
      dispatch({ type: "GOT_MAP_DATA", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_MAP_DATA" });
    });
};

export const fetchBinFeedBacAction = (binId) => async (dispatch) => {
  dispatch({ type: "GETTING_FEEDBACK" });
  metaBinManagement
    .get(`/rating/read/${binId}`)
    .then((res) => {
      dispatch({ type: "GOT_FEEDBACK", payload: res.data.reverse() });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_FEEDBACK" });
    });
};

export const getTopUsers = (binId) => async (dispatch) => {
  dispatch({ type: "GETTING_TOP_USERS" });
  metaBinManagement
    .get(`/metabins/read/admin/top-users/${binId}`)
    .then((res) => {
      dispatch({ type: "GOT_TOP_USERS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_TOP_USERS" });
    });
};

export const getOrganizationAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ORGANIZATION" });
  metaBinManagement
    .get("/organization/read")
    .then((res) => {
      dispatch({ type: "GOT_ORGANIZATION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ORGANIZATION" });
    });
};

export const inivitAccountAction = (email) => async (dispatch) => {
  dispatch({ type: "INVITING_ACCOUNT" });
  metaBinManagement
    .put("/organization/update/invite-account", {
      email: email,
      role: "manager",
    })
    .then((res) => {
      dispatch({ type: "INVITED_ACCOUNT", payload: res.data });
      toast.success(res.data);
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVITE_ACCOUNT" });
      notifyError(error.response ? error.response.data : error.message);
    });
};

export const getTopoMapDataAction = () => (dispatch) => {
  dispatch({ type: "GETTING_TOPO_JSON_MAP" });
  metaBinManagement
    .get("/organization/read/organization-map-data")
    .then((res) => {
      dispatch({ type: "GOT_TOPO_JSON_MAP", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_TOPO_JSON_MAP" });
    });
};

export const editBinLocation =
  (locationDetails, binId, callBack) => async (dispatch) => {
    console.log("ld", locationDetails);
    try {
      const { data } = await metaBinManagement.put(
        `/metabins/update/${binId}`,
        locationDetails
      );
      dispatch(getsingleMetaBinAction(binId));
      callBack();
    } catch (error) {}
  };

export const resetMetabinData = (binId, callBack) => async (dispatch) => {
  try {
    await metaBinManagement.put(`/metabins/update/reset/${binId}`, {});
    dispatch(getsingleMetaBinAction(binId));
    callBack();
  } catch (ex) {
    notifyError(ex.response?.data);
  }
};

export const addBarcode = (data, callBack) => async (dispatch) => {
  try {
    await metaBin.post(`/barcodes/create/unverified`, data);
    callBack();
  } catch (ex) {
    notifyError(ex.response ? ex.response.data : ex.message);
  }
};
