import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../helpers/helper";
import { useNavigate } from "react-router-dom";
import { getTopUsers } from "../redux/action/MetaBinAction";

function TopUsers() {
  const navigate = useNavigate();
  const { binID } = useParams();
  const dispatch = useDispatch();
  const { topUsers } = useSelector((state) => state.topUsers);

  useEffect(() => {
    dispatch(getTopUsers(binID));
  }, [binID, dispatch]);

  return (
    <div>
      <span className="flex align-center ">
        <span
          className="material-symbols-outlined hover mr-1"
          onClick={() => navigate(-1)}
        >
          keyboard_backspace
        </span>
        <h5 className="bold"> Top Metabin Users</h5>
      </span>

      <div className="box white p-1 mt-1 ">
        <table className="striped">
          <thead>
            <tr>
              <th className="primary-text">No.</th>
              <th className="primary-text">User</th>
              <th className="primary-text">Count</th>
            </tr>
          </thead>

          <tbody>
            {topUsers.map((item, index) => {
              return (
                <tr className="semi-small-text" key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <p>{item.account.name}</p>
                    <label className="small-text">
                      +{item.account.phoneNumber}
                    </label>
                  </td>
                  <td>{item.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopUsers;
