import React from "react";
import AuthHeader from "../layout/AuthHeader";
import Footer from "../layout/Footer";
import Features from "../components/landing/Features";
import ControlCenter from "../components/landing/ControlCenter";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

function LandingPage() {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width: 1210px)" });
  let token;
  useEffect(() => {
    token = localStorage.getItem("METABIN_CCXT_Daahboard");
  }, []);

  return (
    <div>
      <AuthHeader />
      <div className="container ">
        <div
          className={
            !isTablet
              ? "flex align-center space-between "
              : "flex column align-center  "
          }
        >
          <div style={{ width: isTablet ? "100%" : "40vw" }}>
            <b style={{ fontSize: "3rem" }}>
              Upgrade your waste management game with{" "}
              <span className="purple-text">Metabins.</span>
            </b>
            <p className="grey-text">It's time to make a difference!</p>
            <span>
              <button
                className="btn mt-2"
                style={{ width: "10rem" }}
                onClick={() => {
                  token ? navigate("/") : navigate("/login");
                }}
              >
                Login
              </button>
            </span>
          </div>
          <img src="../images/main-image.png" style={{ width: "40vw" }} />
        </div>
        <Features />
        <ControlCenter />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
