import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-materialize";
import GeneralSettings from "../components/settings/GeneralSettings";
import ChangePassword from "../components/settings/ChangePassword";
import NotificationSettings from "../components/settings/NotificationSettings";
import EditProfile from "../components/settings/EditProfile";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getProfileAction , getOrganizationAction } from "../redux/action/MetaBinAction";
import Loading from "./Loading";

function Settings() {
  const [editProfile, setEditProfile] = useState(false);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const { profile, loading } = useSelector((state) => state.profileStore);

  useEffect(() => {
    dispatch(getProfileAction());
    dispatch(getOrganizationAction());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container mt-2 metabin-card">
      <div className="flex space-between  p-1 align-center">
        <div className="flex align-center">
          {/*<span
            className="material-symbols-outlined circle purple lighten-5 purple-text p-1"
            style={{ fontSize: "3rem" }}
          >
            person
  </span>*/}

          <img
            src="../images/user_placeholder.jpg"
            className="circle"
            style={{ height: "90px", width: "90px" }}
            alt="profile"
          />

          <div className="ml-1">
            <p className="card-title capitalize ">{profile.name}</p>
            <p className="grey-text">+{profile.phoneNumber}</p>
            <p className="grey-text">{profile.email}</p>
          </div>
        </div>
        {/*!isTablet && (
          <img
            src="../images/cerclex.png"
            style={{ width: "80px", height: "80px" }}
          />
        )*/}
      </div>

      <Tabs>
        <Tab title="General">
          {" "}
          {editProfile ? (
            <EditProfile />
          ) : (
            <GeneralSettings editProfile={setEditProfile} />
          )}
        </Tab>
        <Tab title="Change Password">
          <ChangePassword />
        </Tab>
        <Tab title="Notification Settings">
          <NotificationSettings />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
