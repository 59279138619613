import React from "react";

function TimeLine({ color ,icon}) {
  return (
    <div className="time-line">
      <div className="time-line-dot" >
      {icon}
      </div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
  );
}

export default TimeLine;
