import React from "react";
import PaymentsHistory from "./PaymentsHistory";
import PaymentHistoryChart from "./PaymentHistoryChart";
import PaymentsHistoryAnalytics from "./PaymentsHistoryAnalytics";
function TrackExpenses() {
  return (
    <div>
    <PaymentsHistoryAnalytics/>
      <div className="mt-2  ">
        <div className="flex align-center space-between mb-1  ">
          <span>
            <b>Payments History</b>
            <p className="small-text grey-text ">
              Find all your transactions happening through your wallet and card.
            </p>
          </span>

          <button className="btn-outline">View All</button>
        </div>
        <PaymentsHistory />
        <PaymentHistoryChart />
      </div>
    </div>
  );
}

export default TrackExpenses;
