import React from "react";
import { useMediaQuery } from "react-responsive";

function MediaAccounts() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  return (
    <div className="flex full-width space-between p-1 faq-content red">
      <div style={{ width: "90%" }} className="flex column space-between   ">
        <div>
          <h5>Frequently Asked Question</h5>
          <p>
            If you can not find answer to your question in our FAQ, you can
            always contact us or email us. We will answer you shortly!
          </p>
        </div>
      </div>
      {!isTablet && (
        <img
          src="../images/faq.png"
          style={{ width: "300px", height: "100%", objectFit: "contain" }}
        />
      )}
    </div>
  );
}

export default MediaAccounts;
