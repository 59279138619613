import React from "react";
import Frame from "../layout/Frame";
import MediaAccounts from "../components/help/MediaAccounts";
import FAQ from "../components/help/FAQ";

function Help() {
  return (
    <>
      <MediaAccounts />
      <FAQ />
    </>
  );
}

export default Help;
