import React from "react";
import { useMediaQuery } from "react-responsive";

function DashboardStatistics({ analytics }) {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  return (
    <div
      className={
        isTablet ? "flex flex-wrap gap-1 mv-1 mt-2 column " : "row  flex gap-1 "
      }
    >
      <div
        className="metabin-card p-1 full-width flex column mt-1 "
        style={{ width: !isMobile && "22vw", position: "relative" }}
      >
        <div className="full-width flex justify-end ">
          <span className="card-image" style={{ backgroundColor: "#e1d7f7" }}>
            <span className="material-symbols-outlined cercle-purple-text ">
              delete
            </span>
          </span>

          <span
            className="flex  percentage-change "
            style={{ borderColor: "#50c55d", color: "#50c55d", opacity: 0 }}
          >
            {" "}
            <span className="material-symbols-outlined">trending_up</span>
            <p> {100}%</p>
          </span>
        </div>
        <div className="flex column ">
          <b className="cercle-purple-text">Total Metabins</b>
          <span className="flex align-center ">
            <b className="card-title">{analytics.total }</b>
            <b className="small-text">&nbsp; MetaBins</b>
          </span>
          <span className="line flex "></span>
          <p className="small-text grey-text mt-1  mt-1">
            Number of smartBin.{" "}
          </p>
        </div>
      </div>

      <div
        className="metabin-card p-1 full-width flex column mt-1"
        style={{ width: !isMobile && "22vw", position: "relative" }}
      >
        <div className="full-width flex justify-end ">
          <span className="card-image" style={{ backgroundColor: "#fff4e5" }}>
            <span
              className="material-symbols-outlined bin-icon "
              style={{ color: "#ff9800" }}
            >
              recycling
            </span>
          </span>

          <span
            className="flex  percentage-change "
            style={{ borderColor: "#50c55d", color: "#50c55d" }}
          >
            {" "}
            <span className="material-symbols-outlined">trending_up</span>
            <p> {100}%</p>
          </span>
        </div>
        <div className="flex column ">
          <b className="cercle-purple-text">Total Recycled</b>
          <span className="flex align-center ">
            <b className="card-title">{analytics.weightRecycled}</b>
            <b className="small-text">&nbsp; M.T</b>
          </span>
          <span className="line flex "></span>
          <p className="small-text grey-text mt-1 ">
            {" "}
            Number of metric tons has been recycled.
          </p>
        </div>
      </div>

      <div
        className="metabin-card p-1 full-width flex column mt-1"
        style={{ width: !isMobile && "22vw", position: "relative" }}
      >
        <div className="full-width flex justify-end ">
          <span className="card-image" style={{ backgroundColor: "#c8e8cc" }}>
            <span
              className="material-symbols-outlined bin-icon "
              style={{ color: "#42c756" }}
            >
              inventory_2
            </span>
          </span>

          <span
            className="flex  percentage-change "
            style={{ borderColor: "#50c55d", color: "#50c55d" }}
          >
            {" "}
            <span className="material-symbols-outlined">trending_up</span>
            <p> {100}%</p>
          </span>
        </div>
        <div className="flex column ">
          <b className="cercle-purple-text">Product Collected</b>
          <span className="flex align-center ">
            <b className="card-title">{analytics?.itemsCollected }</b>
            <b className="small-text">&nbsp;</b>
          </span>
          <span className="line flex "></span>
          <p className="small-text grey-text mt-1 ">
            {" "}
            Number of bottles has been Collected.
          </p>
        </div>
      </div>

      <div
        className="metabin-card p-1 full-width flex column mt-1"
        style={{ width: !isMobile && "22vw", position: "relative" }}
      >
        <div className="full-width flex justify-end ">
          <span className="card-image" style={{ backgroundColor: "#dddcf7" }}>
            <span
              className="material-symbols-outlined bin-icon "
              style={{ color: "#5450cc" }}
            >
              local_shipping
            </span>
          </span>

          <span
            className="flex  percentage-change "
            style={{ borderColor: "#50c55d", color: "#50c55d" }}
          >
            {" "}
            <span className="material-symbols-outlined">trending_up</span>
            <p> {100}%</p>
          </span>
        </div>
        <div className="flex column ">
          <b className="cercle-purple-text">Daily Active Sessions</b>
          <span className="flex align-center ">
            <b className="card-title">{0}</b>
          </span>
          <span className="line flex "></span>
          <p className="small-text grey-text mt-1 ">
            {" "}
            An average of all sessions initiated per day
          </p>
        </div>
      </div>

      <div
        className="metabin-card p-1 full-width flex column mt-1"
        style={{ width: !isMobile && "22vw", position: "relative" }}
      >
        <div className="full-width flex justify-end ">
          <span className="card-image" style={{ backgroundColor: "#eedcf7" }}>
            <span
              className="material-symbols-outlined bin-icon "
              style={{ color: "#a92bed" }}
            >
              person_add
            </span>
          </span>

          <span
            className="flex  percentage-change "
            style={{ borderColor: "#50c55d", color: "#50c55d" }}
          >
            {" "}
            <span className="material-symbols-outlined">trending_up</span>
            <p> {100}%</p>
          </span>
        </div>
        <div className="flex column ">
          <b className="cercle-purple-text">User Growth</b>
          <span className="flex align-center ">
            <b className="card-title">{analytics?.userGrowth?.sixMonthsAgo }</b>
            <b className="small-text">&nbsp; Users</b>
          </span>
          <span className="line flex "></span>
          <p className="small-text grey-text mt-1 ">
            {" "}
            New users that interacted with your metabin in the last 6 months.
          </p>
        </div>
      </div>
    </div>
  );
}

const styles = {
  iconStyle: {
    backgroundColor: "#FBF1FF",
    width: "54px",
    height: "54px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

export default DashboardStatistics;
