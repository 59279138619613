import React from "react";
import { useMediaQuery } from "react-responsive";

function EditProfile() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <div className="white p-1 ">
      <form className={isTablet ? "flex column align-center " : "flex"}>
        <div className="flex column  full-width ">
          <div className="search-input flex align-center full-width ">
            <span className="material-symbols-outlined grey-text ">person</span>
            <input
              className="browser-default input-text"
              type="text"
              placeholder="Full Name"
            />
          </div>

          <div className="search-input flex align-center full-width mt-1  ">
            <span className="material-symbols-outlined grey-text">mail</span>
            <input
              className="browser-default "
              type="text"
              placeholder="Email Address"
            />
          </div>

          <div className="search-input flex align-center full-width mt-1 ">
            <span className="material-symbols-outlined grey-text">call</span>
            <input
              className="browser-default "
              type="text"
              placeholder="Phone Number"
            />
          </div>

          <div className="search-input flex align-center full-width  mt-1">
            <span className="material-symbols-outlined grey-text">domain</span>
            <input
              className="browser-default "
              type="text"
              placeholder="Organization"
            />
          </div>

          <div className="search-input flex align-center full-width  mt-1">
            <span className="material-symbols-outlined grey-text">
              location_on
            </span>
            <input
              className="browser-default "
              type="text"
              placeholder="Location / Address"
            />
          </div>
          <div className="mt-2 flex justify-end gap-1 ">
            <button className="btn-outline">Cancel</button>
            <button className="btn" style={{ width: "10rem" }} disabled >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
