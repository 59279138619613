import React from "react";
import { useMediaQuery } from "react-responsive";

function BinsAnalytics({ metaBinsList }) {
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });


  let activeBins = metaBinsList.filter((bind) => bind.status === "active");
  let inactiveBins = metaBinsList.filter((bind) => bind.status === "inactive");
  let filledBins = metaBinsList.filter((bind) => bind.fillLevel >= 93);

  return (
    <div
      className={
        !isMobile ? "flex   gap-1 mv-2" : " flex   gap-1 mv-2 flex-wrap"
      }
    >
      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border cercle-purple"></span>

        <div className="bin-icon" style={{ backgroundColor: "#F4E7FF" }}>
          <span className="material-symbols-outlined  p-1 cercle-purple-text">
            delete
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Metabins Count</p>
          <b className="card-title mt-1 ">{metaBinsList.length}</b>
          <p className="small-text grey-text ">Number of metabins you own</p>
        </div>
      </div>

      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border green"></span>

        <div className="bin-icon  green lighten-5">
          <span className="material-symbols-outlined  p-1 green-text">
            delete
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Active Bins</p>
          <b className="card-title mt-1 ">{activeBins?.length} </b>
          <p className="small-text grey-text ">Number of barcode scaned</p>
        </div>
      </div>

      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border red"></span>

        <div className="bin-icon red lighten-5 ">
          <span className="material-symbols-outlined  p-1 red-text">
            delete
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Inactive Bins</p>
          <b className="card-title mt-1 ">{inactiveBins.length + 5 }</b>
          <p className="small-text grey-text ">Number of barcode scaned</p>
        </div>
      </div>

      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border orange"></span>

        <div className="bin-icon orange lighten-5 ">
          <span className="material-symbols-outlined  p-1 orange-text">
            delete
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Bins Filled Up</p>
          <b className="card-title mt-1 ">{filledBins.length}</b>
          <p className="small-text grey-text ">Number of barcode scaned</p>
        </div>
      </div>
    </div>
  );
}

export default BinsAnalytics;
