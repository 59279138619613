import React from "react";

function Toastify({ notification }) {
  return (
    <div className="flex column " >
      <b className="cercle-purple-text">Bin Update</b>
      <p>{"The smart bin at Location has just collected Amount of Product"}</p>
    </div>
  );
}

export default Toastify;
