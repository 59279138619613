import React from "react";
import { useMediaQuery } from "react-responsive";

function CO2EmissionAvoided() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  return (
    <div>
      <div
        className={
          isTablet
            ? "flex align-center  gap-2 column "
            : "flex align-center  gap-2 "
        }
      >
        <img
          src="../images/co2.png"
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
          loading="lazy"
          alt="co2"
        />

        <div className=" full-width  ">
          <b className="cercle-purple-text normal-size bold">
            Carbon Emission Avoided
          </b>
          <span className="flex align-center mv-2 ">
            <h2 className="bold cercle-purple-text">00</h2>
            <b>&nbsp;kg</b>
          </span>
          <p>
            Our efforts have resulted in a significant reduction of 0.00
            kilotons of CO2 emissions, which is equivalent to taking 0.00 cars
            off the road for a year.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CO2EmissionAvoided;
