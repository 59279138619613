import React from "react";

function AllVouchersTable() {
  return (
    <div>
      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">NO.</th>
            <th className="primary-text">Date</th>
            <th className="primary-text">location</th>
            <th className="primary-text">Vouchers</th>
            <th className="primary-text">Persons</th>
            <th className="primary-text">Gift Partner</th>
            <th className="primary-text flex align-center justify-center">
              Status
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="small-text">
            <td>1.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="redeemed">Redeemed</td>
          </tr>

          <tr className="small-text">
            <td>2.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="expired">Expired</td>
          </tr>

          <tr className="small-text">
            <td>1.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="redeemed">Redeemed</td>
          </tr>

          <tr className="small-text">
            <td>2.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="expired">Expired</td>
          </tr>

          <tr className="small-text">
            <td>1.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="redeemed">Redeemed</td>
          </tr>

          <tr className="small-text">
            <td>2.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="expired">Expired</td>
          </tr>

          <tr className="small-text">
            <td>1.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="redeemed">Redeemed</td>
          </tr>

          <tr className="small-text">
            <td>2.</td>
            <td className="capitalize">12/11/2022</td>
            <td>coimbatore</td>
            <td>4</td>
            <td>2</td>
            <td>CocaCola</td>
            <td className="expired">Expired</td>
          </tr>

        </tbody>
      </table>
    </div>
  );
}

export default AllVouchersTable;
