import React from "react";
import Frame from "../layout/Frame";
import { Tab, Tabs } from "react-materialize";
import AllVouchersTable from "../components/tables/AllVouchersTable";
import PendingVouchers from "../components/tables/PendingVouchers";
import ExpiredVouchers from "../components/tables/ExpiredVouchers";
import RedeemedVouchers from "../components/tables/RedeemedVouchers";


function Vouchers() {
  return (
    <>
      <div>
        <Tabs className="tab-demo z-depth-1" scope="tabs-22">
          <Tab title="All"><AllVouchersTable/></Tab>

          <Tab title="Pending"><PendingVouchers/></Tab>

          <Tab title="Redeemed"><RedeemedVouchers/></Tab>

          <Tab title="Expired"><ExpiredVouchers/></Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Vouchers;
