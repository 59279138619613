import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBinFeedBacAction } from "../../redux/action/MetaBinAction";
import { formatDate } from "../help/helper";
import { useNavigate } from "react-router-dom";
import { Card } from "react-materialize";

function UsersFeedBack({ binId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { feedBack } = useSelector((state) => state.binFeedBack);

  useEffect(() => {
    dispatch(fetchBinFeedBacAction(binId));
  }, [dispatch]);

  return (
    <Card style={{ height: "100%" }} className="p-1">
      <div className="flex align-center space-between ">
        <b>Users FeedBack</b>
        <div
          role="button"
          className="btn-outline"
          onClick={() => navigate(`/feedback/${binId}`)}
        >
          View All
        </div>
      </div>
      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">No.</th>
            <th className="primary-text">Date</th>
            <th className="primary-text">Rating</th>
            <th className="primary-text">comment</th>
          </tr>
        </thead>

        <tbody>
          {feedBack.map((item, index) => {
            if (index < 5)
              return (
                <tr className="semi-small-text" key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{formatDate(item.createdAt)}</td>
                  <td>
                    {" "}
                    <div className="flex align-center gap-1 ">
                      <span className="flex">
                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 1 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 2 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 3 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 4 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 5 ? "gold" : "grey" }}
                        >
                          star
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>{item.comment}</td>
                </tr>
              );
          })}
        </tbody>
      </table>
      {feedBack.length === 0 && (
        <div className="flex full-width p-1 column align-center justify-center mt-2 mb-2 ">
          <span className="material-symbols-outlined large grey-text ">
            comment
          </span>
          <p className="grey-text small-text">
            There is no feedBack right now.
          </p>
        </div>
      )}
    </Card>
  );
}

export default UsersFeedBack;
