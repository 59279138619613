import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { colorTheme } from "./reducer/theme";
import {
  getAllMetaBinsReducer,
  getSingleMetaBinReducer,
  getProfileReducer,
  getNotificationsReducer,
  getAnalyticsReducer,
  getSingleMetaBinSessionReducer,
  getProductsCollectedByDateReducer,
  getProductsSessionReducer,
  getSingleBinSessionReducer,
  getSessionAnalyticsReducer,
  getProductsCollectedAnalytivsReducer,
  getMapDataReducer,
  getBinFeedBackReducer,
  getOrganizationReducer,
  getUserEngagementDataReducer,
  getTopoMapDataReducer,
  getTopUsersReducer,
} from "./reducer/metaBin";

import { loadingReducer } from "./reducer/loading";
import { authReducer } from "./reducer/authReducer";

const reducer = combineReducers({
  theme: colorTheme,
  allMetaBins: getAllMetaBinsReducer,
  singleMetaBin: getSingleMetaBinReducer,
  profileStore: getProfileReducer,
  notificationsStore: getNotificationsReducer,
  analyticsStore: getAnalyticsReducer,
  userEngagement: getUserEngagementDataReducer,
  singelBinSessions: getSingleMetaBinSessionReducer,
  productsCollectedByDate: getProductsCollectedByDateReducer,
  productsSessionsStore: getProductsSessionReducer,
  singleBinSessionStore: getSingleBinSessionReducer,
  getSessionAnalytics: getSessionAnalyticsReducer,
  productsCollectedAnalytivs: getProductsCollectedAnalytivsReducer,
  getMapData: getMapDataReducer,
  binFeedBack: getBinFeedBackReducer,
  organizationStore: getOrganizationReducer,
  loading: loadingReducer,
  topoMapData: getTopoMapDataReducer,
  auth: authReducer,
  topUsers: getTopUsersReducer,
});
const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
