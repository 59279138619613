import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNotificationsAction } from "../../redux/action/MetaBinAction";
import { formatDate } from "../../helpers/helper";

function Notifications() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notificationsStore);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, [dispatch]);
  return (
    <div className="notifications p-1 ">
      <b>Notifications</b>
      <p className="grey-text small-text  ">
        You have ({notifications.length}) notification
      </p>
      <hr />

      {notifications.map((item, index) => {
        return (
          <div>
            <span className="flex align-center  space-between  ">
            <span className="flex align-center " >
              <span
                class="material-symbols-outlined circle purple-text"
                style={{
                  backgroundColor: "#f4e3ff",
                  padding: "5px",
                  margin: "5px"
                }}
              >
                notifications
              </span>

              <p style={{ marginBottom: "5px" }}>{item.title}</p>

              </span>

              <p>{formatDate(item.createdAt)}</p>
            </span>

            <p
              style={{
                padding: "8px 10px",
                borderRadius: "7px",
                backgroundColor: "#f9fafc",
                marginBottom: "5px",
                fontSize: "0.9rem"
              }}
              
            >
              {item.message.split(/\s+/).slice(0, 28).join(" ")} ...
            </p>
            <hr style={{ borderColor:"#efefef" }} />
          </div>
        );
      })}

      {notifications.length == 0 && (
        <div className="flex column center align-center full-width p-2">
          <span
            className="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            notifications_active
          </span>
          <b className="grey-text small-text ">
            Hey! You have no any notifications
          </b>
        </div>
      )}
    </div>
  );
}

export default Notifications;
